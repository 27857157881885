import ReactDOM from "react-dom";
import store from "./store/store";
import Config from "./router/routes";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./plugins/graphql";
import { motion, AnimatePresence } from "framer-motion";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ErrorBoundary from "./ErrorBoundary";

import "./index.css";
/* import "antd/dist/antd.css"; */
import "../src/styles/popup.css";
import "../src/styles/modaltheme.css";
import "./utils/i18nextInit";
import { AbilityContext } from "./components/shared/AbilityContext";
import { VertebraAbilityBuilder } from "./components/shared/AbilityBuilder";
import { updateAbility } from "./utils/auth";
import { createBrowserRouter } from "./router/AppRouter";
import { RouterProvider } from "react-router-dom";
import ReactGA from "react-ga4";
import { backendGoogleAnalitycs } from "./utils/enviroments";
import 'dayjs/locale/es';
import dayjs from "dayjs";

ReactGA.initialize([
  {
    trackingId: backendGoogleAnalitycs ?? "",
    gaOptions: {
      testMode: true,
    },
  },
]);

const BrowserRouter = createBrowserRouter(Config);
//console.log(router.routes);

const client = createApolloClient();

const ability = VertebraAbilityBuilder();
updateAbility(ability);

const url = window.location.href;

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={
      url.includes("vertebra-tech.com")
        ? "6LfmVLQoAAAAADfgN6dQTolEklGSHaZeHTQQR6ES"
        : "6LeL2REeAAAAAH2RKS6R8yanGoy4H6-1DpYFcRTB"
    }
    language="esp"
    // useRecaptchaNet=true
    // useEnterprise=false
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "body", // optional, default to "head", can be "head" or "body",
      nonce: undefined, // optional, default undefined
    }}
  >
    <Provider store={store}>
      <ApolloProvider client={client}>
        <AnimatePresence>
          <motion.div
            key="animationKey"
            initial="pageInitial"
            animate="pageAnimated"
            exit="pageExit"
            variants={{
              pageInitial: {
                opacity: 0,
              },
              pageAnimated: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              },
              pageExit: {
                opacity: 0,
                transition: {
                  duration: 0.5,
                },
              },
            }}
          >
            <ErrorBoundary>
              <AbilityContext.Provider value={ability}>
                <RouterProvider router={BrowserRouter} />
              </AbilityContext.Provider>
            </ErrorBoundary>
          </motion.div>
        </AnimatePresence>
      </ApolloProvider>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);

// import ReactDOM from "react-dom";
// import store from "./store/store";
// import Config from "./router/routes";
// import { Provider } from "react-redux";
// import { ApolloProvider } from "@apollo/client";
// import { createApolloClient } from "./plugins/graphql";
// import { motion, AnimatePresence } from "framer-motion";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import ErrorBoundary from "./ErrorBoundary";

// import "./index.css";
// /* import "antd/dist/antd.css"; */
// import "../src/styles/popup.css";
// import "../src/styles/modaltheme.css";
// import "./utils/i18nextInit";
// import { AbilityContext } from "./components/shared/AbilityContext";
// import { VertebraAbilityBuilder } from "./components/shared/AbilityBuilder";
// import { updateAbility } from "./utils/auth";
// import { createBrowserRouter } from "./router/AppRouter";
// import { RouterProvider } from "react-router-dom";
// import Login from "./components/pages/login";

// const BrowserRouter = createBrowserRouter(Config);
// //console.log(router.routes);

// const client = createApolloClient();

// const ability = VertebraAbilityBuilder();
// updateAbility(ability);

// const router = createBrowserRouter([
//   {
//     path: "/login",
//     //exact: true,
//     element: <Login />,
//     //canActivate: [IsLogged],
//   },
//   {
//     path: "/",
//     element: ,
//   },
// ]);

// ReactDOM.render(
//   <GoogleReCaptchaProvider
//     reCaptchaKey="6LeL2REeAAAAAH2RKS6R8yanGoy4H6-1DpYFcRTB"
//     language="esp"
//     // useRecaptchaNet=true
//     // useEnterprise=false
//     scriptProps={{
//       async: false, // optional, default to false,
//       defer: false, // optional, default to false
//       appendTo: "body", // optional, default to "head", can be "head" or "body",
//       nonce: undefined, // optional, default undefined
//     }}
//   >
//     <Provider store={store}>
//       <ApolloProvider client={client}>
//         <RouterProvider router={router} />
//         {/* <AnimatePresence>
//           <motion.div
//             key="animationKey"
//             initial="pageInitial"
//             animate="pageAnimated"
//             exit="pageExit"
//             variants={{
//               pageInitial: {
//                 opacity: 0,
//               },
//               pageAnimated: {
//                 opacity: 1,
//                 transition: {
//                   duration: 0.5,
//                 },
//               },
//               pageExit: {
//                 opacity: 0,
//                 transition: {
//                   duration: 0.5,
//                 },
//               },
//             }}
//           >
//             <ErrorBoundary>
//               <AbilityContext.Provider value={ability}>
//                 <RouterProvider router={BrowserRouter} />
//               </AbilityContext.Provider>
//             </ErrorBoundary>
//           </motion.div>
//         </AnimatePresence> */}
//       </ApolloProvider>
//     </Provider>
//   </GoogleReCaptchaProvider>,
//   document.getElementById("root")
// );
