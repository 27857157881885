{
  "login": "Log in",
  "user": "User",
  "password": "Password",
  "enter": "ENTER",
  "recover": "Recover credentials",
  "info_card": "For support on the SmartEco application please send an email to",
  "user_not_valid": "Please enter a valid user!",
  "user_password_valid": "Please enter a valid password",
  "password_size": "Password must be at least 12 characters long",
  "recovery_options": "Recovery options",
  "form": "Form",
  "recover_user": "Recover user",
  "recover_password": "Recover password",
  "cancel": "Cancel",
  "label_email": "Email",
  "send_email": "Send email",
  "recover_password_capital_letter": "RECOVER PASSWORD",
  "name_user_capital_letter": "Username",
  "field_required": "This field is required",
  "new_password_capital_letter": "NEW PASSWORD",
  "recover_password_text": "Remember that the password must be at least 12 characters long",
  "very_weak": "Very weak",
  "weak": "Weak",
  "good": "Good",
  "strong": "Strong",
  "verificate_password_capital_letter": "VERIFY PASSWORD",
  "update_password": "Change Password",
  "all_rights_reserved": "All rights reserved",
  "version": "version",
  "updatePassword": "Password Updated",
  "error_message1": "Oops, a problem has occurred",
  "equal_fields": "Fields must match",
  "shortcut": "Shortcuts",
  "msgIdiom": "Language changed successfully",
  "language": "Language",
  "spanish": "Spanish",
  "english": "English",
  "Portuguese": "Portuguese",
  "SignOff": "Sign off",
  "management": "Management",
  "pane": "Tool pane",
  "viewShortcuts": "See keyboard shortcuts",
  "closeShortcuts": "Close keyboard shortcuts",
  "beginning": "Beginning",
  "updateTable": "Update table",
  "point": "Points",
  "account": "Accounts",
  "groups": "Groups",
  "domains": "Domains",
  "doamin": "Domain",
  "rates": "Rate",
  "transactions": "Transactions",
  "serviceProvider": "Service Provider",
  "entities": "Entities",
  "paymentMethods": "Payment Methods",
  "money": "Money",
  "ResourceSources": "Resource Sources",
  "budget": "Budget",
  "bill": "Bill",
  "Payments": "Payments",
  "audits": "Audits",
  "loads": "Loads",
  "meter": "Meters",
  "search": "Search",
  "reportDownloads": "Report downloads",
  "company": "Company",
  "roles": "Roles",
  "invoiceDashboard": "Invoice Dashboard",
  "dashboardPayments ": "Payments Dashboard",
  "Vertebots": "Vertebots",
  "serviceProviders": "Service Providers",
  "concepts": "Concepts",
  "name": "Name",
  "copy": "Copy",
  "copied": "Copied",
  "abbreviation": "Abbreviation",
  "abb": "Abb",
  "type": "Type",
  "identificationBusiness": "NIT",
  "dv": "DV",
  "service": "Services",
  "movii": "Movii",
  "voucr": "Voucher",
  "email": "Email",
  "yes": "Yes",
  "no": "No",
  "delete": "Delete",
  "edit": "Edit",
  "create": "Create",
  "searchs": "Search",
  "state": "State",
  "activate": "Activate",
  "deactivate": "Deactivate",
  "activeTable": "Active",
  "inactive": "Inactive",
  "subproject": "Subproject",
  "extras": "Extras",
  "seeExtras": "See Extras",
  "extrasOf": "Extras of ",
  "addEquivalence": "Add Equivalence",
  "department": "Department",
  "city": "City",
  "dateFirst": "Start Date",
  "dateEnd": "End Date",
  "total": "Total",
  "record": "Records",
  "detail": "Detail",
  "remember": "Remember",
  "textAlertProvider": "To manage notifications, the account must be edited and the multi-notification option selected.",
  "deleteTextAlertProvider": "Service provider successfully deleted.",
  "upateTextAlertProvider": "Service provider updated to:",
  "updateNotificationProviderActivate": "Notification sending activated",
  "updateNotificationProviderDesactivate": "Notification sending deactivated",
  "manageNotifications": "Manage notifications",
  "activatePetitionBill": "Activate Invoice Petition Sending",
  "deactivatePetitionBill": "Deactivate Invoice Petition Sending",
  "viewProviderInformation": "View provider information",
  "close": "Close",
  "short": "Short",
  "multiNotification": "MULTI NOTIFICATION",
  "phone": "Phone",
  "fieldRequiredNumber": "This field only accepts numbers",
  "fieldRequiredDv": "You must enter the digit number",
  "fielRequiredType": "You must select a type",
  "typeService": "Service Type",
  "fieldRequiredTypeService": "You must select the service type",
  "proffSeding": "VOUCHER SENDING",
  "fieldRequiredOption": "You must select an option",
  "notificationEmail": "Email Notification",
  "regularPayment": "REGULAR PAYMENT",
  "regulatedPayment": "Regulated Payment",
  "reactiveCharge": "Reactive Charge",
  "fieldNitRequired": "You must enter a NIT",
  "alertEntityDelete": "The entity has been successfully deleted.",
  "nameEntity": "Entity Name",
  "stateEnity": "Entity State",
  "notificationDeleteDomains": "The domain has been successfully deleted.",
  "father": "Father",
  "dNotHave": "Does not have",
  "domainCreate": "Domain created",
  "alertDomainCreate": "Your domain was created successfully",
  "selectionFather": "You must select a father",
  "add": "Add",
  "removeFather": "Remove father",
  "alertPaymentDelete": "The payment method has been successfully deleted.",
  "addEquivalenceA": "Add equivalence to",
  "updateConcept": "Update concept",
  "alertDeleteMeter": "Are you sure you want to delete this meter?",
  "deleteMeter": "Meter deleted successfully",
  "updateMeters": "Meter modified successfully",
  "addMeterNews": "Meter created successfully",
  "subprojects": "Subprojects",
  "disableSubProjectText": "Disable subprojects if not applicable for the service type",
  "deleteTarFeeAlert": "Are you sure you want to delete this fee?",
  "alertDeleteFee": "Fee deleted successfully",
  "createRate": "Create rate",
  "updateRate": "Edit rate",
  "viewFiles": "View File",
  "noFiles": "No files",
  "voltageLevel": "Voltage Level",
  "propiety": "Property",
  "detailOf": "Detail of",
  "recolection": "Collection",
  "publicToilet": "Public Toilet",
  "complementary": "Complementary",
  "sumptuary": "Sumptuary",
  "energeticValue": "Energetic Value",
  "deleteUserQuestion": "Are you sure you want to delete this user?",
  "deleteUserAlert": "User deleted successfully",
  "updateUserQuestion": "Are you sure you want to change the status of this user?",
  "updateUserAlert": "User status modified",
  "nickName": "Nickname",
  "lastAdmission": "Last Admission",
  "verified": "Verified",
  "notVerified": "User verification pending",
  "updateState": "Change state",
  "assign": "Assign",
  "access": "Access",
  "office": "Offices",
  "notification": "Notifications",
  "download": "Download",
  "accessUpdatedAlert": "Access updated successfully",
  "group": "Group",
  "result": "Result",
  "userAccessRepeat": "This user has all groups assigned",
  "Ops": "Oops",
  "valueRequired": "This value is required",
  "regionals": "Regionals",
  "typeUser": "User Type",
  "allRegionals": "All Regionals",
  "selectionAll": "Select All",
  "deleteAssign": "Delete assignments",
  "textUserAssgin": "To continue, please select a company",
  "assignReportBi": "Assign BI Reports",
  "report": "Reports",
  "all": "All",
  "url": "URL",
  "reportDownload": "Download Reports",
  "assignReportDownload": "Report assignment",
  "alertOfficeRegister": "Offices registered successfully",
  "permissions": "Permissions",
  "removed": "Removed",
  "saveChanges": "Save to save changes.",
  "save": "Save",
  "alertMessageNotifications": "All notifications will be deactivated.",
  "alertConfigNotifications": "Notification Settings updated",
  "configNotification": "Configure notifications",
  "activateConfirm": "Activated",
  "deactivateConfirm": "Deactivated",
  "messageNotifications": "All company notifications have been deactivated",
  "notificationBillCompany": "Notify if company's bill",
  "wasLoaded": "was loaded.",
  "notificationVoucherCompany": "Notify if company's payment voucher",
  "itRejected": "was rejected.",
  "notifyIf": "Notify if",
  "billAndVouchers": "bill and/or vouchers is loaded.",
  "addRolSave": "The role has already been added for this company",
  "userSaveUpdated": "User updated successfully",
  "userGetInto": "Please enter a name!",
  "lastName": "Last Name",
  "lastNameUsergetInto": "Please enter a last name!",
  "emailError": "Please enter a valid email!",
  "rolAplication": "Application Role",
  "assignRolCompany": "Assign roles by company",
  "generatePassword": "Generate password",
  "pleasePassworValidate": "Please enter a valid password!",
  "users": "Users",
  "rolDeleteAlert": "Are you sure you want to delete this role?",
  "rolDeleteAlertConfirm": "Role deleted successfully",
  "updateRolAlert": "Are you sure you want to change the status of this role?",
  "updateRolAlerModif": "Role status modified",
  "cancelAlert": "Canceled",
  "actionUpdateCancel": "State change action canceled",
  "rolModif": "Role modified successfully",
  "createRol": "Role created successfully",
  "pointDeleteAlert": "The point has been deleted successfully.",
  "notVerificed": "",
  "admiPoint": "Manage point",
  "viewInfoPoint": "View point information",
  "viewHistoryPoint": "View point history",
  "generateCertificed": "Generate certificate",
  "order": "Order",
  "direction": "Direction",
  "others": "Others",
  "location": "Location",
  "power": "Power",
  "amount": "Amount",
  "noData": "No data",
  "enterPointName": "You must enter a point name",
  "namePoint": "Point Name",
  "fullName": "Full Name",
  "enterCityName": "You must enter the city name",
  "enterDptoName": "You must enter the department",
  "enterLocationName": "You must enter an address",
  "paymentNotification": "Payment notification",
  "sendNotificationPayment": "Send payment notification to payment",
  "pointState": "Point state",
  "officeCode": "Office Code",
  "entrerCodOffice": "You must enter the office code",
  "numberEmployer": "Number of employees",
  "area": "Area",
  "latitude": "Latitude",
  "fielObligatoryLatitude": "This field is mandatory if you enter a longitude",
  "length": "Longitude",
  "fieldObligatoryLength": "This field is mandatory if you enter a latitude",
  "namePonitShort": "You must enter a short name for the point",
  "updateStateExit": "The account status was successfully changed",
  "dateFirstOperation": "Date of first operation",
  "dateSelection": "You must select a date",
  "dateUpdateText": "Date of status change",
  "infoPoint": "Point information",
  "employer": "Employees",
  "codOffice": "Office code",
  "allowsNotifications": "Allows notifications",
  "update": "Change",
  "shedule": "Schedules",
  "enterDate": "Enter a date",
  "enterDateEnd": "Enter an end date",
  "accountDelete": "The account has been deleted successfully",
  "editAuthorizations": "Edit authorizations",
  "UploadArchive": "Upload flat file",
  "addNovelty": "Add novelty",
  "viewNovelty": "View novelties",
  "viewInfoAccount": "View account information",
  "viewHistoryAcoount": "View account history",
  "number": "Number",
  "dates": "Dates",
  "Cleanliness": "Cleanliness",
  "water": "Water",
  "enegy": "Energy",
  "Gas": "Gas",
  "sewerage": "Sewerage",
  "streetLighting": "Street Lighting",
  "novelty": "Novelties",
  "authorized": "Authorized",
  "paymentInfoCompleted": "Payment Info",
  "newComercio": "New marketer from",
  "previusAccount": "Previous account",
  "inactiveSince": "Inactive since",
  "authorizedPayment": "Authorized payment",
  "dataAccount": "Account data",
  "codVerification": "Verification code",
  "enterNumberAccount": "You must enter an account number",
  "numberMeter": "Meter number",
  "emailNotification": "Notification email",
  "markertType": "Market type",
  "infoLocation": "Location information",
  "selectionRegional": "You must select the regional",
  "selectionPoint": "You must select a point",
  "selectionService": "You must select the service provider",
  "dateEmition": "Date of issue",
  "dateStimedPayment": "Estimated payment date",
  "timelyPaymentDate": "Timely payment date",
  "initialBillingDate": "Initial billing date",
  "dateInitial": "Initial date",
  "BudgetsAndPayments": "Budgets and payments",
  "frequency": "Frequency",
  "accountFather": "Parent account",
  "socioeconomicLevel": "Socioeconomic level",
  "paymentVertebra": "Payment vertebra",
  "authorizedForPayment": "Authorized for payment",
  "clientUploadBill": "Client uploads bill",
  "serviceDate": "Service Dates",
  "StreetLighting": "Street Lighting",
  "selectionFrecuency": "You must select the frequency",
  "uploadFileOk": "Flat file upload successful",
  "dateBill": "Bill date",
  "dateReal": "Real date",
  "billingPeriod": "Billing period",
  "dateBillDate": "Billing date",
  "suspencionDate": "Suspension date",
  "period": "Period",
  "upSupport": "Upload support",
  "fieldRequired": "Field required!",
  "clickUploadVoucher": "Click or drag the voucher",
  "uploadFile": "Upload file",
  "changeOfMarketer": "Change of marketer",
  "numberAccount": "Account number",
  "estimatedDateOFIssue": "Estimated date of issue",
  "tymelyPaymentDate": "Timely payment date",
  "infoPaymentVertebra": "Payment vertebra information",
  "allowPayment": "Allow payment",
  "typeNovelty": "Type of novelty",
  "comentary": "Comments",
  "observation": "Observation",
  "comentaryNovelty": "Novelty comment",
  "newNovelty": "Create novelty",
  "shortName": "Short name",
  "allowPaymentNotificatios": "Allow payment notifications",
  "inactivationDate": "Inactivation date",
  "propertyLevel": "Property level",
  "coordinates": "Coordinates",
  "dateOfIssue": "Date of issue",
  "numberVerification": "Verification number",
  "serviceName": "Service name",
  "lastBill": "Last bill",
  "selectRecordTheChange": "Select a record to view the changes",
  "export": "Export",
  "userMakingtheChange": "User making the change",
  "operationDate": "Operation date",
  "alertDeleteRates": "Are you sure you want to delete this rate?",
  "rateDeleteOk": "Rate deleted successfully",
  "closeNovelty": "Close novelty",
  "addComentary": "Add comment",
  "deleteGroupAlert": "Group deleted successfully",
  "groupCode": "Group code",
  "groupName": "Group name",
  "errorNameRegional": "You must enter a regional name",
  "selectionStateError": "You must select a state",
  "stateGroup": "Group status",
  "transaction": "Transaction",
  "resourceSource": "Resource source",
  "BillToPay": "Bills to pay",
  "newTransaction": "Create transaction",
  "closeTransaction": "Close transaction",
  "concilationsView": "View reconciliations",
  "uploadFileConcilation": "Upload reconciliation file",
  "operationType": "Operation type",
  "bankVerification": "Bank verification",
  "menu": "Menu",
  "transactionVerified": "Transaction verified",
  "amounts": "Amounts",
  "initialBalance": "Initial balance",
  "endBalance": "End balance",
  "responsible": "Responsible",
  "reconcile": "Reconcile",
  "viewInfoBill": "View bill information",
  "detailBill": "Bill detail",
  "noveltyDeleteOk": "Novelty deleted successfully",
  "comentaryDeleteOk": "Comment deleted successfully",
  "sourceIdResource": "Resource source ID",
  "sourceNameOfTheresource": "Resource source name",
  "sourceIdCompany": "Company source ID",
  "sourceNameOfTheCompany": "Company source name",
  "place": "Place",
  "contactAccount": "Contract account",
  "marketer": "Marketer",
  "numberBill": "Bill number",
  "typeRegister": "Register type",
  "totalToPay": "Total to pay",
  "paymentMade": "Payment made",
  "dateOfBills": "Bill date",
  "dateSuspencion": "Suspension date",
  "paymentDate": "Payment date",
  "viewBill": "View bill",
  "updateCompany": "Company changed",
  "updateQuuestionCompany": "Are you sure you want to change the status of this company?",
  "stateCompanyAlert": "Company status modified",
  "assignNotification": "Assign notifications",
  "newCompany": "Create company",
  "editCompany": "Edit company",
  "manager": "Manager",
  "change": "Change",
  "updateTransaction": "Edit transaction",
  "reconciliations": "Reconciliations",
  "manualReconciliations": "Manual reconciliations",
  "originOfMoney": "Origin of money",
  "paymentMethod": "Payment method",
  "paymentReference": "Payment reference",
  "valuePaid": "Value paid",
  "voucherNumber": "Voucher number",
  "enablePaymentVertebra": "Enabled for vertebral payment",
  "enableForPayment": "Enabled for payment",
  "seeProof": "See proof",
  "creation": "Creation",
  "billLoad": "Bill load",
  "typedBy": "Typed by",
  "approvedBy": "Approved by",
  "voucherUpload": "Voucher upload",
  "conceptsForConsumption": "Concepts for consumption",
  "toiletPrice": "Toilet price",
  "contributionForTheToilet": "Contribution for the toilet",
  "totalPaidInToilet": "Total paid in toilet",
  "activeEnergyCurrentMeasurement": "Current measurement of active energy",
  "previusEnergyCurrentMeasurement": "Previous measurement of active energy",
  "activePowerEnergy": "Active energy power factor",
  "activeEnergyConsumption": "Active energy consumption",
  "activeEnergyRate": "Active energy rate",
  "activeEnergyPrice": "Active energy price",
  "activeEnergyContribution": "Contribution for active energy",
  "currentMesurementOrReactiveEnergy": "Current measurement of reactive energy",
  "previusMesurementOrReactiveEnergy": "Previous measurement of reactive energy",
  "reactivePowerfactor": "Reactive energy power factor",
  "reactiveEnergyComsuption": "Reactive energy consumption",
  "reactiveEnergyrate": "Reactive energy rate",
  "reactiveEnergiPrice": "Reactive energy price",
  "contributionForReactiveEnergy": "Contribution for reactive energy",
  "hourOfInterruption": "Hours of interruption",
  "totalPaidEnergy": "Total paid in energy",
  "currentMeasureOfAqueduct": "Current measure of aqueduct",
  "previusMeasureOfAqueduct": "Previous measure of aqueduct",
  "waterComsuption": "Water consumption",
  "basicAqueductRate": "Basic aqueduct rate",
  "coplementaryAqueductRate": "Complementary aqueduct rate",
  "suntaryAqueductRate": "Suntuary aqueduct rate",
  "basicFixedPriceAqueduct": "Basic fixed price aqueduct",
  "complementaryFixedPriceAqueduct": "Complementary fixed price aqueduct",
  "fixedPriceQueduct": "Fixed price aqueduct",
  "aqueductContribution": "Aqueduct contribution",
  "contributionForAqueductFixedPrice": "Contribution for fixed price aqueduct",
  "basicSewerRate": "Basic sewer rate",
  "sumpturySewerRate": "Sumptuary sewer rate",
  "basicFIxedPriceAqueduct": "Basic fixed price aqueduct",
  "coplementarySewerRate": "Complementary sewer rate",
  "coplementaryFixedPriceAqueduct": "Complementary fixed price aqueduct",
  "fixedPriceSumptaryAqueduct": "Fixed price sumptuary aqueduct",
  "seweragePrice": "Sewerage price",
  "fixedPriceAqueduct": "Fixed price aqueduct",
  "fixedPriceSewerage": "Fixed price sewerage",
  "contributionForSewerage": "Contribution for sewerage",
  "contributionForFixedSeweragePrice": "Contribution for fixed sewerage price",
  "pricePaidForTheAqueductP": "Price paid for the aqueduct",
  "pricePaidForTheAqueduct": "Price paid for the aqueduct",
  "totalOther": "Total other",
  "voluntaryContribution": "Voluntary contribution",
  "otherToilet": "Other toilet",
  "observations": "Observations",
  "noComment": "No comments",
  "deleteComentaryAlertBill": "You are about to delete a comment from the bill",
  "notComentary": "No comments available",
  "deleteNoveltyBillAlert": "You are about to delete a novelty from the bill",
  "notNovelty": "No novelties available",
  "tracing": "Tracing",
  "by": "by",
  "at": "at",
  "sorceId": "Source ID",
  "source": "Source",
  "destinityID": "Destination ID",
  "destinity": "Destination",
  "date": "Date",
  "reference": "Reference",
  "transferAmount": "Transferred amount",
  "resource": "Resource",
  "transactionInfo": "Transaction information",
  "refrenceInfo": "Reference information",
  "operationBillAsiigned": "The operation has no assigned bill.",
  "paymentInfo": "Payment information",
  "auditInfo": "Audit information",
  "notNoveltyAdd": "No novelties added",
  "notConceptsAdd": "No concepts added",
  "selectionDate": "You must select a date",
  "transactioExits": "The transaction has been successfully closed",
  "startDateOperation": "Start date of operation",
  "errorFileUpload": "Error uploading file.",
  "imageSizeErr": "The image must not be larger than 20MB",
  "transactionEditOk": "Transaction edited successfully",
  "transactionNewOk": "Transaction created successfully",
  "nameOperation": "Operation name",
  "numberBillEnter": "Enter the bill number",
  "numberReferencia": "Reference number",
  "lockedReturn": "Lock return",
  "selectionRatesFile": "Click or drag the transaction receipt",
  "uploadVaucher": "Upload vouchers",
  "uploadFileBIll": "You must upload an image of the bill",
  "editRates": "Edit transactions",
  "newRates": "Create transaction",
  "returnRates": "Return transaction",
  "fileUploadOk": "File uploaded successfully",
  "uploadReconciliation": "Upload reconciliation",
  "reportNewOk": "Report created successfully",
  "yourReporNewOk": "Your report was created successfully",
  "manualReconciliation": "Manual reconciliation",
  "recolitianOk": "Reconciliation performed successfully",
  "totallySelection": "Total selected",
  "reconconciliationManully": "Perform manual reconciliation",
  "successConsoliditedOperation": "Operation consolidated successfully",
  "send": "Send",
  "addFounds": "Add funds",
  "editFounds": "Edit funds",
  "updateQuestion": "Are you sure you want to change the status?",
  "stateResourseOrigen": "Resource origin status",
  "balance": "Balance",
  "incomeMonth": "Income month",
  "expensesMonth": "Expenses month",
  "seeDetails": "See details",
  "viewBalanceHistory": "View balance history",
  "foundAddSuccefully": "Fund added successfully",
  "balances": "Balances",
  "uploadProof": "Upload proofs",
  "proodUploadFile": "Click or drag the transaction receipt",
  "detailRates": "Transaction detail",
  "lastClosing": "Last closing",
  "newResourceSource": "Create resource origin",
  "editResourceSource": "Edit resource origin",
  "moveFounds": "Move funds",
  "resourceWithoutNumber": "Resource origin without number",
  "toAccept": "To accept",
  "deleteResourceSourceAlert": "Are you sure you want to delete this resource origin?",
  "resourceSourceDeleteOk": "Resource origin deleted successfully",
  "styateResourceSourceUpdate": "Resource origin state changed successfully",
  "assignResourceSource": "Resource origin assignment",
  "pricipally": "Principal",
  "children": "Children",
  "income": "Income",
  "expenses": "Expenses",
  "alert": "Alerts",
  "entitie": "Entity",
  "assignAccess": "Assign accesses",
  "sourceResourceOk": "Resource origin created successfully",
  "resourceHierachy": "Resource hierarchy",
  "foundTransfer": "Fund transferred successfully",
  "originResource": "Resource origin",
  "transactionDetail": "Transaction detail",
  "region": "Region",
  "reg": "Reg",
  "janury": "January",
  "jan": "Jan",
  "febraury": "February",
  "feb": "Feb",
  "march": "March",
  "mar": "Mar",
  "april": "April",
  "apr": "Apr",
  "May": "May",
  "mays": "May",
  "june": "June",
  "july": "July",
  "jun": "Jun",
  "jul": "Jul",
  "august": "August",
  "aug": "Aug",
  "september": "September",
  "sep": "Sep",
  "october": "October",
  "oct": "Oct",
  "november": "November",
  "nov": "Nov",
  "december": "December",
  "dec": "Dec",
  "general": "General",
  "gas": "Gas",
  "admin": "Admin",
  "tFija": "Fixed T.",
  "tMovil": "Mobile T.",
  "tv": "Television",
  "stateAccount": "Account status",
  "supplier": "Supplier",
  "restoreInvoice": "Restore invoice",
  "seeInvoiceAndReceipt": "See invoice and receipts",
  "uploadfBill": "Upload invoice",
  "unpaidInvoiceForVertebra": "Unpaid invoice for Vertebra",
  "NotDataViews": "No data to display",
  "accountPointBlock": "Blocked account or point",
  "retoreInvoicePeding": "Restore pending invoice",
  "restore": "Restore",
  "support": "Support",
  "forPayment": "For payment",
  "tracklingUpdateOk": "Tracking updated successfully",
  "noveltyPayment": "Payment novelties",
  "follows": "Follows",
  "yourRemoveBillFollow": "You are about to remove a follow-up from the invoice",
  "revised": "Revised",
  "revisedAccount": "Revised account",
  "billUpdatedAlertPunding": "Are you sure you want to restore this invoice to PENDING?",
  "areYouSure": "Are you sure?",
  "billUpdateAlertFingered": "You are about to change your invoice status to ENTERED",
  "paymentNotAuthorized": "Payment not authorized",
  "month": "Month",
  "editDateAssing": "Edit assigned date",
  "statePaynment": "Payment status",
  "plataformNotRegister": "Not registered on platform",
  "stateFingered": "State in typing",
  "companyes": "Companies",
  "statics": "Statistics",
  "rejectBillAlert": "Are you sure you want to restore the rejected invoice?",
  "billRestoredOk": "Invoice restored successfully",
  "budgetaryAddAccount": "Are you sure you want to add this budget account?",
  "accountAddOk": "Account successfully added to the budget",
  "isSecureBill": "Are you sure you want to review this invoice?",
  "accountRevised": "Accounts revised",
  "isSecurePaymenChildren": "Are you sure you want to propagate payment to child invoices?",
  "paymentOkAlert": "Payment propagated successfully",
  "viewVoucher": "View Voucher",
  "reAsign": "Reassign",
  "addNoveltyAccount": "Add account novelty",
  "restoredRechazed": "Restore Rejected",
  "addBudget": "Add to budget",
  "changeBudget": "Change budget",
  "Outof_budget": "Out of budget",
  "autorizedPayment": "Authorize payment",
  "addNewInvoce": "Add Invoice Novelty",
  "addNoveltyToTracking:": "Add Novelty to Tracking",
  "slopes": "Pending",
  "totallyPayment": "Total to pay",
  "paid": "Paid",
  "totalPaid": "Total paid",
  "assigns": "Assigned",
  "infoBill": "Invoice information",
  "billPendient": "Pending invoices",
  "amountPendient": "Pending amount",
  "porcetain": "Percentage",
  "rulesAmount": "Amount Rules",
  "providers": "Providers",
  "new": "New",
  "assignMissing": "Assign Missing",
  "redistribute": "Redistribute",
  "uploadChart": "Uploading chart",
  "updateDate": "Data updated.",
  "and": "and",
  "ejectBoots": "Bot execution",
  "paymentMarketerService": "Most paid Service P.",
  "digitBillAlerts": "Typing, Digitized Invoice Alerts",
  "energyConsumerd": "Energy consumed",
  "lastUpdated": "Last updated:",
  "value": "Value",
  "status": "Status",
  "vertebots": "Vertebots",
  "downloadBill": "Invoice downloads",
  "infovertebots": "Vertebot information",
  "ejecstBoots": "Run vertebots",
  "idFather": "Father ID",
  "idOFCompany": "Company ID",
  "totalFather": "Total Father",
  "noStartDate": "No start date",
  "noEndDate": "No end date",
  "completed": "Completed",
  "mistake": "Mistakes",
  "viewDetailOperation": "View Operation Detail",
  "displayInformationAbout": "Display information about",
  "hideInformationFrom": "Hide information from",
  "displayLogOf": "Display log of",
  "hideLogFrom": "Hide log from",
  "uploadedSuccessfully": "Uploaded successfully",
  "whenTryingAlert": "when trying to upload the file, try again error",
  "vertebotStartOk": "Vertebot started successfully",
  "ocrEjectOk": "OCR executed successfully",
  "oneMeter": "One meter",
  "allMeter": "All meters",
  "youMustUploaderFile": "You must upload a file",
  "clickUploadFile": "Click or drag the file",
  "selectionDateMeter": "Select a date",
  "selectionDateDownloadBullAndAccount": "Select a date to download all invoices for an account",
  "treeDownload": "Download tree",
  "attempts": "Attempts",
  "noBillingDate": "No billing date",
  "lastDateAttempts": "Last attempt date",
  "noDateAttemptsColumns": "No last attempt date",
  "datUpdate": "Update date",
  "notDateUpdate": "No update date",
  "dateNew": "Creation date",
  "notDateNew": "No creation date",
  "selectionOne": "Select one",
  "selectionOptionViews": "Select an option to view consumption dashboards",
  "rememberOption": "Remember this option may take a while",
  "newLoad": "Create load",
  "configurateAvanzedLoad": "Advanced load configuration for meter type",
  "updatesMeters": "Edit Meter",
  "stateMeterUpdate": "Meter state modified",
  "actionStateCanceled": "State change action canceled",
  "infoLoad": "Load information",
  "createAlertMeter": "Create meter alerts",
  "description": "Description",
  "meteer": "Meter",
  "typeMeter": "Meter type",
  "viewUrl": "View URL",
  "configAvanzed": "Advanced Config",
  "viewDetail": "View detail",
  "loadUpdateOk": "Load modified successfully",
  "newLoadOk": "Load created successfully",
  "meterss": "Meters",
  "inserDescriptionLoad": "Enter a load description...",
  "uploadSupport": "Upload support",
  "textRepeatVoucherUpload": "There is already an uploaded voucher, uploading a new one will delete the existing voucher.",
  "instalation": "Installation",
  "multiplicationFactor": "Multiplication factor",
  "relationCTS": "CTS relationship",
  "typeOfMeasure": "Measure type",
  "circuitType": "Circuit type",
  "powerFactor": "Power factor",
  "voltage": "Voltage",
  "createAlert": "Create alert",
  "editAlert": "Edit alert",
  "alertDeleteAlert": "Are you sure you want to delete this alert?",
  "alertDeteleOk": "Alert deleted successfully",
  "var": "Variable",
  "temp": "Time",
  "alertUpodateOk": "Alert modified successfully",
  "alertNewOk": "Alert created successfully",
  "pleaseInsertVar": "Please insert a variable!",
  "pleaseInsertValue": "Please insert a value",
  "pleaseInsertTemp": "Please insert a valid time",
  "nameOfCompany": "Company name",
  "idGroup": "Group ID",
  "nameOfGroup": "Group name",
  "idPoint": "Point ID",
  "nameOfPoint": "Point name",
  "idMeter": "Meter ID",
  "nameMeter": "Meter name",
  "viewResource": "View Resource",
  "idOfLoad": "Load ID",
  "nameOfLoad": "Load name",
  "infoAvanzedLoad": "Advanced load information",
  "operationInfo": "The operation has no information",
  "newMeter": "Create meter",
  "avanzedConfig": "Advanced configuration",
  "ediMeter": "Edit meter",
  "infoMeter": "Meter information",
  "updatePasswordMeter": "Change meter password",
  "serial": "Serial",
  "protocol": "Protocol",
  "dateInstalation": "Installation date",
  "lastReport": "Last report",
  "serialOfmMeter": "Meter serial",
  "deviceName": "Device name",
  "errorAddinfo": "Error adding information",
  "codNameLastAdd": "The code or database name has already been added",
  "NameBDLastAdd": "The database name has already been added",
  "addProtocolMod": "Add modbus protocols",
  "addHeader": "Add headers",
  "dataAcquisition": "Data acquisition",
  "frecuencyUnit": "Frequency unit",
  "frecuencyValue": "Frequency value",
  "configHeader": "Header configuration",
  "addConfigHeader": "Add header configuration",
  "nameHeader": "Header name",
  "nameBD": "DB name",
  "action": "Action",
  "directionIp": "IP Address",
  "port": "Port",
  "protocolModbus": "Modbus Protocol",
  "addProtocol": "Add Protocol",
  "register": "Register",
  "dataLength": "Data length",
  "tData": "Data Type",
  "typeData": "Data Type",
  "unit": "Unit",
  "concentrator": "Concentrator",
  "sim": "SIM",
  "simNumber": "SIM Number",
  "subType": "Subtype",
  "nameOfHeader": "Header name",
  "idConcentrator": "Concentrator ID",
  "adquitionData": "Data acquisition",
  "infoOfMeter": "Meter information",
  "infoAvanzedMeter": "Advanced meter information",
  "configModBus": "Modbus Configuration",
  "notInfoOperation": "The operation has no information.",
  "operation": "Operation",
  "typeEmail": "Email Type",
  "detailAudit": "Audit Detail",
  "fileNoXlsx": "The file is not xlsx",
  "fileNoZip": "The file is not zip",
  "uploader": "Uploader",
  "selectEntitie": "You must select an entity",
  "selectAction": "You must select an action",
  "downloadExample": "Download Example",
  "selectionFile": "You must select a file",
  "selectFile": "Select file",
  "selectOneField": "You must select at least one field",
  "selectMergeUnique": "Select unique combination...",
  "stringZero": "Take blanks as empty string",
  "selectionMethods": "Select method",
  "firstProcess": "Start Process",
  "History": "History",
  "entiti": "Entity",
  "process": "Process",
  "mergeUnique": "Unique Combination",
  "treatmentUpdating": "Treatment when updating",
  "aprovved": "Approved",
  "isSecureQuestion": "Are you sure?",
  "generateReport": "Generate report",
  "dateOfReport": "Report date",
  "selectionYear": "You must select the year",
  "criteryDownload": "Download criterion",
  "selectionCritery": "You must select a criterion",
  "criteryEDownload": "Criterion to download",
  "allGroups": "All groups",
  "allCompanies": "All companies",
  "selectionAllGroup": "You must select at least one group",
  "AllGroupsWereSelected": "All groups were selected",
  "AllCompaniesWereSelected": "All companies were selected",
  "reportCreateExit": "Report created successfully",
  "selectionMonth": "You must select the month",
  "selectionDates": "Select a date",
  "allPoint": "All points",
  "selectionOnePoint": "You must select at least one point",
  "selectioAllPoint": "All points were selected",
  "selectionMeter": "You must select at least one meter",
  "AllMeterSelection": "All meters were selected",
  "inserDateValidate": "You must enter a valid date",
  "typesServices": "Service types",
  "selectionOneService": "You must select at least one service",
  "selectionAllService": "All services were selected",
  "selectionOneDigitation": "You must select at least one digitizer",
  "selectionAllType": "All types were selected",
  "creates": "Created",
  "periode": "Period",
  "payment": "Paid",
  "dateUpload": "Upload date",
  "dateAssing": "Assigned date",
  "dateExpedition": "Expedition date",
  "selectionOneRegional": "You must select at least one region",
  "options": "Options",
  "consumedMonth": "Monthly consumption",
  "separateCleanLight": "Separate cleaning",
  "viewsSubProject": "Show Subproject",
  "toChooseAllMarketer": "All marketers were chosen",
  "firstDateReport": "First date of the report",
  "secondDateReport": "Second date of the report",
  "yearAndMonth": "Year and month",
  "allServices": "All services",
  "ConvertValuePositive": "Convert to Positive Values",
  "logSistem": "System Log",
  "newVersion": "New version",
  "allAccount": "All accounts",
  "selectionOneAccount": "You must select at least one account",
  "allsAccount": "All accounts were selected",
  "reportCreaoteOkAlert": "Report created successfully",
  "selectionOneGroup": "You must select one group",
  "monthReport": "Report month",
  "load": "Load",
  "preview": "Preview",
  "engrave": "Engrave",
  "budgetAvailible": "No budgets available",
  "check": "Check",
  "payBill": "Pay bill",
  "reviewBill": "Review bill",
  "changePayment": "Change Payment",
  "spradPaymentAccount": "Spread payment account",
  "viewInfo": "View Information",
  "createNoveltys": "Create Novelties",
  "staticsBill": "Billing Statistics",
  "selectionBudgetAddBill": "Select a budget to add to the bill",
  "config": "Configuration",
  "accountRestore": "Account restored",
  "accountGirl": "Child account",
  "outOfBudget": "Out of budget",
  "NotPlataformRegister": "Not registered on platform",
  "expiration": "Expiration",
  "suspend": "Suspension",
  "due": "Due",
  "notBNudget": "No budget",
  "dataPayment": "Payment data",
  "delet": "Delete",
  "TheFileDelete": "The file has been deleted",
  "recordUploadFile": "Remember that a new invoice will be uploaded, indicating that more than one invoice has arrived for this month",
  "continue": "continue",
  "newUploabill": "Upload a new invoice",
  "newUpdateBill": "Change Invoice",
  "alertRejectBill": "Remember that rejected invoices will be completely deleted",
  "rejectBill": "Reject invoice",
  "deleteBill": "Delete invoice",
  "deleteVoucher": "Delete voucher",
  "dateBillPrompt": "Prompt payment date",
  "NotObservations": "No observations",
  "infoBillOf": "Invoice information of",
  "obsOfTheBill": "Invoice observations",
  "noveltyOfTheBill": "Invoice novelties",
  "followBill": "Invoice follow-ups",
  "conceptTheBill": "Invoice concepts",
  "moneyBags": "Money bags",
  "phones": "Phones",
  "mobilePhone": "Mobile phone",
  "day": "Day",
  "selection": "Select",
  "obs": "Observation",
  "selections": "Selected",
  "recharge": "Recharge",
  "aPublic": "Public",
  "accountInactive": "Inactive account",
  "pointInactive": "Inactive point",
  "accountAndPointInactive": "Account and point inactive",
  "viewAdmiPoint": "View Admin. Points",
  "notInvoiced": "Not invoiced",
  "configUpdateNotification": "Notification configuration updated",
  "configTextNotification": "Notifications configuration of",
  "notificarEi": "Notify to",
  "whenUploadBill": "when an invoice is uploaded",
  "whenUploadVoucher": "when a voucher is uploaded",
  "whenRejectBill": "when an invoice is rejected",
  "whenSendPetitionBillMarketer": "invoice request by email",
  "notificationTypeUser": "Notify to user type",
  "managers": "Manager",
  "payer": "Payer",
  "client": "Client",
  "loa": "Load",
  "notTypeUser": "No user type",
  "petitionBillAcoount": "Invoice request to account",
  "companyUpdateOk": "Company updated successfully",
  "companyNewOk": "Company created successfully",
  "pleaseInserrNameValid": "Please insert a valid name!",
  "pleaseInsertTypeValid": "Please insert a valid type!",
  "updateStateAccount": "Change account status",
  "auditOfPoint": "Point audits",
  "updateStatePointAsk": "Are you sure you want to change the status of this point?",
  "stateOfPointUpdate": "Point status modified",
  "verificedStanBY": "Standby verification",
  "correo": "Mail",
  "stateOfPoint": "Point status",
  "exportListUpdate": "Export change listings",
  "permitNotificationPayment": "Permit payment notifications",
  "authorizedPaymet": "Authorized payment",
  "lvlSocio": "Socioeconomic level",
  "lvlVoltage": "Voltage level",
  "userRealizedUpdate": "User who made the change",
  "notDateOfOperation": "No operation date",
  "noveltyAccount": "Account novelties",
  "insertFilePlaneEnel": "Upload parent file of Enel",
  "updateStateofAccount": "Change account status",
  "IsSecureUpodateStateAccountAsk": "Are you sure you want to change the status of this account?",
  "stateAcconuntModif": "Account status modified",
  "auditAccountNumber": "Account audit number",
  "ofPoint": "of point",
  "pServicio": "P. Service",
  "dateTI": "Dates T - I",
  "accountFathers": "Father account",
  "idNeuAccount": "New account ID",
  "newNameOfAccount": "New account name",
  "newMarketerService": "New service provider",
  "updateRealizedFor": "change made by",
  "lastMarketer": "previous marketer",
  "updateMarketerForUser": "The user changed the marketer",
  "he": "he",
  "eiTheMarketer": "the marketer",
  "withAccountNumber": "with account number",
  "notUpdateMarketer": "The marketer was not changed",
  "dateTimelyPayment": "Timely payment date",
  "dateInitialOfBilling": "Initial billing date",
  "paymentAuthorized": "Payment authorized",
  "authorizedForPaiment": "Authorized for payment",
  "dateServices": "Services dates",
  "NumberCuentaEnergy": "Energy account number",
  "numberAccountOfStreetLight": "Public A. account number",
  "numberAccountWater": "Water account number",
  "numberAccountOfSewerage": "Sewerage account number",
  "numberAccountCleanlines": "Cleaning account number",
  "numberAccountGas": "Gas account number",
  "voltageLvl": "Voltage level",
  "infoOdfTheAccount": "Account information",
  "editNovelty": "Edit novelties",
  "theGroupDeleteOk": "The group has been deleted successfully",
  "codOfgroup": "Group code",
  "notName": "No name",
  "dateUploadOfBill": "Date of invoice upload",
  "dateOfPaymentBill": "Payment date",
  "back": "Back",
  "companys": "Companies",
  "nameOftheTransaction": "Transaction name",
  "balanceFirst": "Initial balance",
  "balanceEnd": "Final balance",
  "clickFileRates": "Click or drag the transaction file",
  "manualReconciliationss": "Manual reconciliation",
  "reconciliation": "Reconciled",
  "notReconciliation": "Not reconciled",
  "notDate": "No date",
  "notReconciliatioons": "Not reconciled",
  "operationConsolidedOk": "Operation consolidated successfully",
  "historyBalances": "Balance history",
  "uploadProofs": "Upload proofs",
  "lastClose": "Last close",
  "dateNotOfFirst": "Start date",
  "createResouceSource": "Create resource source",
  "sourceResourceNotNumber": "Resource source without number",
  "states": "States",
  "sorceResourceOk": "Resource source correctly",
  "budgetCalculation": "Budget calculation",
  "gestionBudget": "Budget management",
  "isSecurteDeleteBudgetAlertAsk": "Are you sure you want to delete this budget?",
  "budgetDeleteOk": "Budget deleted successfully",
  "isSecureAskAprovedBudget": "Are you sure you want to approve this budget?",
  "theBudgetisAproved": "The budget",
  "isSecureDuplicateBudgetAsk": "Are you sure you want to duplicate this budget",
  "budgetDuplicateOk": "Budget duplicated successfully",
  "selectAQuoteToSync": "Select a budget to sync",
  "budgetCreateOk": "Budget created successfully",
  "note": "Note",
  "textBudgetOne": "The accounts used for budget calculation are those authorized for payment. If the invoices have not arrived and have not been digitized, the approximate payment date and approximate payment amount will be used.",
  "generateBuget": "Generate Budget",
  "dateCreate": "Creation date",
  "view": "View",
  "aprovedQuote": "Approve budget",
  "downloadDetail": "Download Detail",
  "duplicateQuote": "Duplicate budget",
  "syncBudget": "Sync budget",
  "budgetToSyncOk": "Budget synchronized successfully",
  "accounrAddOk": "Account added successfully",
  "isPaymentregisterBudget": "This payment is already registered in the budget",
  "budgetUpdateOk": "Budget modified successfully",
  "isSecureUpdateQuotes": "Are you sure you want to change the status of the budget",
  "budgetSaveOk": "Budget saved successfully",
  "addAccountBudget": "Add account to budget",
  "filterBills": "Filter Invoices",
  "lastBills": "Last Invoice",
  "codeOffice": "Office Code",
  "createRole": "Create role",
  "editRole": "Edit role",
  "notsverificied": "Not Verified",
  "notSendNotificationsAlert": "Notification sending has been deactivated",
  "sendNotificationsAlert": "Notification sending has been activated",
  "petitionBillDesactivate": "Invoice Request Deactivated",
  "petitionBillActivate": "Invoice Request Activated",
  "codMovii": "Movii Code",
  "digitVerification": "Verification digit",
  "insertNumberDigitation": "You must enter the verification number",
  "modo": "Mode",
  "notificationDelete": "Notification deleted successfully",
  "deleteNotificationAlerQuestion": "Are you sure you want to delete this notification?",
  "createNotification": "Create notification",
  "editNotification": "Edit notification",
  "updateNotificationAlert": "Notification modified successfully",
  "notificationCreateOk": "Notification created successfully",
  "petitionBill": "Invoice request to",
  "createConcepts": "Create concept",
  "subService": "Sub Service",
  "textOneCreateConcetp": "Disable subprojects if it does not apply to the service type",
  "equevalenceExist": "This equivalence already exists",
  "equivalence": "Equivalence",
  "atMenosEquivalence": "At least one equivalence",
  "fieldObligatoeyValue": "This field is mandatory, please enter a value",
  "nameOfEquevalence": "Equivalence name",
  "addNewqEquivalence": "Add a new equivalence",
  "saveEquievalence": "Save equivalence",
  "saveUpdate": "Save changes",
  "confir": "Confirm",
  "configs": "Settings",
  "audit": "Audit",
  "odoo": "Odoo",
  "emailss": "Email",
  "psERVICE": "Service Provider",
  "notCity": "No city",
  "notdeparment": "No department",
  "notPropieruty": "No property",
  "notLvlVariedade": "No voltage level",
  "rateOptional": "Optional Rate",
  "basic": "Basic",
  "valueFije": "Fixed Value",
  "saldeMinime": "Minimum Wages",
  "ratesUpdateOk": "Rate modified successfully",
  "ratesOkCreate": "Rate created successfully",
  "generates": "Generation",
  "tranmition": "Transmission",
  "distribution": "Distribution",
  "comercialization": "Commercialization",
  "retriction": "Restrictions",
  "lossesAndReduction": "Losses and reductions",
  "valueEnergy": "Energy value",
  "optionTarida": "Tariff option",
  "valueOption": "Optional value",
  "textRatesOne": "A file is already uploaded, uploading a new one will delete the previous one",
  "clickFileRatess": "Click or drag the rate file",
  "createSetting": "Create setting",
  "editSetting": "Edit setting",
  "isSecureDeleteSetting": "Are you sure you want to delete this setting?",
  "settingDeleteOk": "Setting deleted successfully",
  "valueColumns": "Values",
  "ValueOf": "Values of ",
  "viewValues": "View values",
  "table": "Tables",
  "tableSyncro": "Synchronized Table",
  "syncro": "Sync",
  "viewss": "View",
  "detailOdoo": "Odoo Detail",
  "displayInformation": "Display Information",
  "ocultInfo": "Hide Information",
  "calculatorAmbiental": "Environmental Calculator",
  "editPoint": "Edit Point",
  "isSecureDeleteElement": "Are you sure you want to delete this element?",
  "elementDeleteOk": "Element deleted successfully",
  "iluminationActivate": "Illumination inversion activated",
  "notIluminationDeactivate": "No illumination inversion",
  "pointVisit": "Point Visited",
  "notPointVisit": "Point visit deactivated",
  "aireVisit": "Air conditioning inversion activated",
  "notAireVisit": "No air conditioning inversion",
  "exportMultiPoint": "Export MultiPoints",
  "iluminations": "Illumination inversion",
  "aire": "Air Conditioning Inversion",
  "visit": "Visited",
  "deleteOeditOne": "You can only delete or edit one at a time",
  "downloadReport": "Download Report",
  "typeOffice": "Office Type",
  "numbersEmployer": "Employee Number",
  "activa": "Active",
  "reactiva": "Reactive",
  "light": "Lighting",
  "acueduct": "Aqueduct",
  "numberAccounts": "Account Number",
  "numberMeters": "Meter Number",
  "shedules": "Schedule",
  "element": "Element",
  "photo": "Photos",
  "elementAddOk": "Element added successfully",
  "assingGestor": "Assign manager users",
  "userAddOk": "Users added successfully",
  "userDeleteOk": "Users deleted successfully",
  "sinAudit": "No audit",
  "auditPayment": "Payment audit",
  "useraddgestor": "Users added to the point successfully",
  "userDeletedGestor": "Users removed from the point successfully",
  "deleteUser": "Delete user",
  "assignUsers": "Assign user",
  "adimin": "Administration",
  "totallyBillAnual": "Total Annual Bill",
  "totallyBillMonth": "Total Monthly Bill",
  "billSinBill": "Bills without upload",
  "billMonth": "Monthly frequency bill",
  "billDigitid": "Digitized bills",
  "billFrequencyMontly": "Bimonthly frequency bill",
  "billPedientDigit": "Pending bills for digitization",
  "billPendie": "Pending bills",
  "billFrequencyTri": "Quarterly frequency bill",
  "billPayment": "Paid bills",
  "billFrequencyAnual": "Annual frequency bill",
  "billReject": "Rejected bills",
  "formule": "Formula",
  "changefrecuency": "Change Frequency of Month",
  "updloadbillmethod": "Upload Bill Method",
  "makechange": "Change made successfully",
  "incumbent_marketer": "Incumbent Provider",
  "emailverification": "Email Verification",
  "removeemailnotification": "Remove Email Verification",
  "executed_mode": "Executed budget",
  "marketer_user_type": "User type",
  "applyfilters": "Applied filters",
  "supervisor": "Supervise payment visualization",
  "companies": "Companies",
  "pay_regionals": "Groups",
  "types_pay": "Types",
  "pays_marketers": "Providers",
  "asignee": "Payer",
  "payorder": "Order",
  "filter": "Filter",
  "supervisortag": "Supervisor",
  "issue": "Issue Date",
  "reviewModal": "Invoice review",
  "review": "Review",
  "reviewsubtype": "Sub type of review",
  "approvepayment": "Approved for payment",
  "removereview": "Remove review",
  "restorereview": "Update review",
  "payappropve": "Approved for pay",
  "approved_payment": "Approved for payment",
  "digitation_status_filter": "Digitizing status",
  "datebill": "Billing start date",
  "estimate_issue_date": "estimated date of issue",
  "budget_apply": "Budget apply",
  "active": "Active",
  "revision_status": "Revision status",
  "services_config": "Service configuration",
  "services_message": "You must choose a service",
  "update_settings": "Settings update",
  "uploadBill": "Load bill",
  "client_upload_bill": "Client upload bill",
  "charger": "Loader",
  "odoo_alert": "Generate odoo alerts",
  "update_bill": "Update bill",
  "consumption_matrix": "Matrix",
  "fat_file": "Fat file",
  "file": "File",
  "conciliations": "Conciliations",
  "movementsandtransfers": "Movements and transfers",
  "created_at": "Created at",
  "voucher_number": "Voucher number",
  "date_paid": "Date paid",
  "user_created": "User created",
  "target": "Target",
  "payment_date": "Payment date",
  "date_bill": "Date bill",
  "real_payment": "Total",
  "paid_date": "Paid date",
  "regional_name": "Regional",
  "number_bill": "Number bill",
  "payment_reference": "Payment reference",
  "cash_source_name": "Cash source",
  "account_name": "Account number",
  "payment_method_marketer_name": "Payment method",
  "is_consolidated_true": "Conciliated",
  "is_consolidated_false": "Not conciliated",
  "payment_tax": "Tax",
  "rollback": "Rollback",
  "user_uploaded_voucher": "Payer user",
  "consolidated": "Consolidated",
  "bill_reviewed": "Rewiew",
  "bill_load": "Log",
  "not_consolidated": "Not Consolidated",
  "property_level": "Property Level",
  "voltaje_level": "Voltage level",
  "phone_number": "Phone Number",
  "guru_groups": "Guruve Groups",
  "clear_selections": "Clear selected",
  "places": "Places",
  "max_three_selected": "You can select up to three items",
  "history": "History",
  "get_history_id_message": "You must select a user to display their history data.",
  "operation_name": "Operation",
  "cash_source": "Movement",
  "see_history": "See user History",
  "messages": "Messages",
  "see_messages": "See user Messages",
  "schedules": "Scheduled Messages",
  "message_title": "Message title",
  "message": "Message",
  "buttons": "Buttons",
  "guruve_buttons_add": "Add buttons to message",
  "section_title": "Section Title",
  "body_message": "Enter the form specifications.",
  "body_message_title": "Body of form",
  "expected_response_type": "Type of response",
  "list_text_message": "Name of the list",
  "guru_message_types": "Type of message",
  "guru_messages_status": "Status of the message",
  "total_sent": "Total sent messages",
  "send_date": "Date sent",
  "send_date_and_hour": "Date and hour of sent",
  "included_groups": "Includes groups",
  "excluded_groups": "Excluded groups",
  "included_users": "Includes users",
  "excluded_users": "Excluded users",
  "includ_all": "Includes all",
  "programing": "Programing",
  "targets": "Targets",
  "get_schedule_message_info": "You must select a message to display their configuration.",
  "duplicated": "Duplicate",
  "aprove": "Aprove",
  "reject": "Reject",
  "send_test": "Send Test",
  "send_date_and_hour_message": "Your message was programmed to be sent at the desired date and time, if you wish to modify it you can do so before the set time.",
  "change_message": "Change message",
  "click_and_drag": "Click or drag file to this area to upload",
  "click_and_drag_message": " Support for a single. Strictly prohibit from uploading company data or other band files",
  "changePriority": "Change Priority",
  "changeNormalToPriority": "Change to Normal Priority",
  "changeHighToPriority": "Change to High Priority",
  "priority": "Prioritized",
  "request_approval": "Request approval",
  "expired": "Expired",
  "building": "Building",
  "ready_for_approval": "Read for approval",
  "scheduled": "Approved and Scheduled",
  "sent": "Sent",
  "rejected": "Rejected",
  "cancelled": "Cancelled",
  "type_upload_method": "Upload method",
  "review_sub_type": "Review sub type",
  "assigned": "Assigned",
  "sending_message": "Sending message",
  "delivered": "Delivered",
  "received": "Received",
  "seen": "Seen",
  "text": "Text",
  "list": "List",
  "media": "Media",
  "button": "Button",
  "button_reply": "Button reply",
  "list_reply": "List reply",
  "sticker": "Sticker",
  "incoming": "Incoming",
  "no_message": "No message",
  "sent_at": "Sent at",
  "delivered_at": "Delivered at",
  "read_at": "Read at",
  "see_more": "See more",
  "information": "Information",
  "add_form_field": "Add form field",
  "add_list_item": "Add list item",
  "add_button_field": "Add button field",
  "edit_schecule_message": "Edit schedule message",
  "saving": "Saving...",
  "payment_method": "Payment method",
  "total_pending": "Total pending",
  "total_pending_priority": "Total pending priority",
  "edit_message_name": "Edit message name",
  "edit_schedule": "Edit content",
  "clear_last_selections": "Clear last selections",
  "total_user_bill_downloads": "Total number of logs completed",
  "estimated_upload_date": "Estimated upload date",
  "upload_month": "Upload month",
  "scheduled_at": "Scheduled at",
  "mode": "Mode",
  "assignee_charger": "Assignee charger",
  "assignee_payer": "Assignee payer",
  "digitation_status": "Digitizing status",
  "user_bill_download": "User bill download",
  "payment_aut": "Payment aut",
  "remove_children": "Remove children",
  "remove_children_account": "Remove of parent account",
  "action_sucesfull": "Action sucesfull",
  "remove_account_of_bill_parent": "Remove bill of account parent",
  "remove_pay_from_bill": "Do you want to remove this invoice from the payment?",
  "payment_method_suggest": "Payment method suggest",
  "direct_debit_payment": "Direct Debit Payment",
  "upload_parent_file_bill": "Upload parent file bill",
  "clickUploadBill": "Click to upload bill",
  "simple_mode": "Simple mode",
  "report_mode": "Report mode",
  "childs": "Childs",
  "parent": "Parent",
  "loader": "Loader",
  "add_selected": "Add selected",
  "automatic_debit": "Automatic debit",
  "energy_border": "Border",
  "account_cession": "Account cession",
  "account_cession_susecfull": "Account cession sucesfull",
  "accounts": "Accounts",
  "stratum": "Stratum",
  "position": "Position",
  "id_card": "Id card",
  "bill_upload": "Bill upload",
  "total files": "Total files",
  "difference": "Difference",
  "reconciled": "Reconciled",
  "notCancelled": "Not cancelled",
  "number_of_agreement": "Number of agreement",
  "date_ipp_ipc_base": "Date IPP/IPC base",
  "contributing_rate": "Contributing rate",
  "collection_method": "Collection method",
  "help": "Help",
  "rates_hepl_one": "Variables stored with a Hash/Pound symbol #",
  "rates_hepl_two": "Leave a space before declaring a variable",
  "rates_hepl_three": "Separate each data with comma for example: (#SMMLV + 30), (#SMMLV+30)*15",
  "rates_hepl_four": "When using a ternary operator remember to use '?' then ':' Example:",
  "rates_hepl_five": "It is used for an '&&' and what is used for an '||'",
  "rates_hepl_six": "In case a nested ternary doesn't have the last validation, please set the value to TRUE.",
  "public_lighting_fee": "Public lighting fee",
  "timely_payment_date_budget": "Estimated payment date budget",
  "daily_number": "Daily number",
  "filter_date": "Filter date",
  "ilumination_office": "Ilimination office",
  "aires_office": "Aires office",
  "ilumination_atm_office": "Ilimination ATM office",
  "aires_atm_office": "Aires ATM office",
  "aire_data_center": "Aire data center",
  "hydric": "Hydric",
  "init_date": "Init date",
  "end_date": "End date",
  "add_investments": "Add investments",
  "base_lines": "Base lines",
  "remove_cession": "Remove cession",
  "bot_upload_bill": "Bot upload bill",
  "implementation_upload_bill": "Implementation upload bill",
  "timely_payment_date": "Timely payment date",
  "consumption": "Consumption",
  "image_bill": "Image bill",
  "create_account": "Create account",
  "upload_bill": "Upload bill",
  "view_upload_bill_info": "View upload bill info",
  "account_relationship": "Account relationship",
  "message_template": "Message template",
  "template_message_intro": "Welcome! In this space, you will have the opportunity to choose the perfect template for your welcome message on WhatsApp.",
  "header": "Header",
  "messages_lobby": "Welcome to Guruve's message creation form! This is where you can configure and personalize your message to your liking.",
  "messages_lobby_1": "Choose and customize the welcome template for your message.",
  "messages_lobby_2": "Create messages according to your preferences.",
  "messages_lobby_3": "Interact with bot users by creating response conditions for the sent messages.",
  "budgetFixed": "Budget fixed",
  "conditions_title": "Conditions",
  "conditions_message_intro": "Here you can create conditions for the messages you send through Guruve, which can be used to validate messages, provide options for the user to select one alternative or another, and more.",
  "conditions_message_example": "These are examples of using a condition: $id_message.value === 'Yes', to query an age, for example, or validate if the invoice consumption is high: $invoice_consumption.value > '100000' or $age.value > '18', among many other queries and validations.",
  "message_intro": "Customize your messages to your liking to be sent through Guruve. You can send videos, images, and stickers.",
  "add_on_tree": "Add to message tree",
  "template_id": "Template ID",
  "user_information": "User information",
  "add_guru_mode_message": "To access this module, you must add a guruve mode to your user profile through the following link:",
  "dates_information": "Dates information",
  "origin_type": "Origin type",
  "start_date": "Start date",
  "destination_name": "Destination name",
  "no_interactions": "You must select an interaction to see the information of the sent messages.",
  "interactions": "Interactions",
  "unreconciledTransactions": "Unreconciled transactions",
  "export_users_bill_upload": "Export users bill upload",
  "export_sent_messages": "export sent messages",
  "points": "Points",
  "carbon_print": "Carbon print",
  "meters_variation": "Meters variation",
  "energy": "Energy",
  "see_carbon_footprint": "See carbon footprint",
  "alerts": "Alerts",
  "total_active_energy": "Total active energy",
  "total_reactive_energy": "Total reactive energy",
  "average_consumption": "Average consumption",
  "actual_savings": "Actual savings",
  "last_year": "Last year",
  "company_information": "Company information",
  "monthly_comparison": "Monthly comparison",
  "values": "Values",
  "energy_consumption": "Energy consumption",
  "reactive_power_consumption": "Reactive power consumption",
  "base_consumption": "Base consumption",
  "hide_accounts_without_data": "Hide accounts without data",
  "flow_finished": "Flow finished",
  "billUpload": "Bill upload",
  "massive_logbook": "Massive logbook",
  "provison_synlab_file": "Provison synlab file",
  "filter_base_consumption": "Filter base consumption",
  "relationship": "Relationship",
  "jump_message_intro": "You can use jumps to jump to a specific message by using the message IDs.",
  "jump": "Jump",
  "projected": "Projected",
  "vertebracanmakethepay": "Vertebra can make the payment",
  "the_user": "The user",
  "old_account_change_marketer": "The user [user_make_change] made a change of supplier from [old_provider] to [new_provider] from account [old_account] to account [new_account] with XM registration [datexm] on [date]. The change is under the modality [modality] and the sales modality is [sales_modality].",
  "new_account_change_marketer": "The user [user_make_change] generated the change of marketer for the account [old_account] to the account [new_account] with Xm registration [datexm], the modification date is [date]",
  "document": "Document",
  "removeChangeMarketer": "Remove change marketer",
  "not_registered": "Not registered",
  "respel": "Respel",
  "internet": "Internet",
  "application": "Application",
  "message_name": "Message name",
  "multiple_company_schedule": "Multiple company schedule",
  "unbilled_month": "Unbilled month",
  "invoice_account": "Invoice account",
  "withoutBiReport": "You do not have reports assigned to this company",
  "see_childrens": "See childrens",
  "see_provider": "Service provider",
  "see_company": "See company",
  "parent_account": "Parent account",
  "Scheduled": "Scheduled",
  "schedule_messages": "Schedule messages",
  "extra_info": "Extra information",
  "message_extra_info": "Add some extra information to your message",
  "error_validation": "Error validation",
  "validation": "Validation",
  "startwith": "Start with",
  "endwith": "End with",
  "subtype": "Subtype",
  "optional_info": "Optional information",
  "message_optional_info": "Add some optional information to your message",
  "exeptionCommand_type": "Exception command type",
  "exeptionCommandText": "Exception command text",
  "sendNext": "Send next",
  "vertebra_can_pay": "Vertebra can pay",
  "type_place": "Type place",
  "changeMarketer": "Change marketer",
  "has_account_cession": "Account cession",
  "novelty_account": "Novelty account",
  "office_code": "Office code",
  "estimatePaymentDate": "Estimate payment date",
  "new_marketer": "New marketer",
  "withoutData": "Without data",
  "withoutInformation": "Without information",
  "street_lighting": "Street lighting",
  "cleanliness": "Cleanliness",
  "switchVerification": "Change verification",
  "insertbillpetition": "Insert bill petition",
  "upload_enel_file": "Upload enel file",
  "assigned_payer": "Assigned payer",
  "estimate_payment_date": "Estimate payment date",
  "initial_payment_date": "Initial payment date",
  "final_payment_date": "Final payment date",
  "estimate_payment_config": "Estimate payment config",
  "issue_date_config": "Issue date config",
  "date_paid_config": "Date paid config",
  "billing_to": "Billing",
  "iva_value": "IVA value",
  "telemetry_value": "Telemetry value",
  "data_plan_vallue": "Data plan value",
  "dateChange": "Date change",
  "sell_modality": "Sell modality",
  "spot_date": "Spot date",
  "financing_date": "Financing date",
  "number_of_payments": "Number of payments",
  "final_pay_date": "Final pay date",
  "remove_change_marketer": "Remove change marketer",
  "export_interactions": "Export interactions",
  "export_unregistered_user_interactions": "Export interactions for unregistered users",
  "discounted_call": "Discounted call",
  "unregistered_users": "Unregistered users",
  "brand": "Brand",
  "typeEquipment": "Equipment type",
  "lightingType": "Lighting type",
  "inventory": "Inventory",
  "account_changes": "Account changes",
  "mode_of_sale": "Mode of sale",
  "offer_number": "Offer number",
  "modality": "Modality",
  "investments": "Investments",
  "change_date_to_regulatory_bodies": "Change date to regulatory bodies",
  "valve": "Valve",
  "meter_characterization": "Meter characterization",
  "include_points_without_investments": "Include points without investments",
  "include_points_without_inventory": "Include points without inventory",
  "with": "With",
  "without": "Without",
  "error": "Error",
  "message_information": "Message information",
  "week": "Week",
  "budget_value": "Budget value",
  "budget_value_not_equal_total": "The budget value is not equal to the total of the budget items",
  "budget_account_date": "Budgeted date",
  "payment_immediate": "Payment immediate",
  "diferent_values": "Diferent values",
  "export_account": "Export account",
  "export_bills_history": "Export bills history",
  "bills_without_export": "Bills without export",
  "current": "Current",
  "previous": "Previous",
  "payment_today": "Payment today",
  "expires_today": "Expires today",
  "active_since": "Active since",
  "compare_budgets": "Compare budgets",
  "indexer": "Indexer",
  "base_date": "Base date",
  "adequacy_value": "Adequacy value",
  "equipment_value": "Equipment value",
  "labor_cost_value": "Labor cost value",
  "exit_penalty_percentage": "Exit penalty percentage",
  "year": "Year",
  "weeks_configuration_json": "JSON for weeks configuration",
  "configure_service_percentages": "Configure service percentages",
  "service_percentages_for_budget_calculation": "Service percentages for budget calculation",
  "market": "Market",
  "payer_mode": "Payer mode",
  "reviewer_modo": "Reviewer mode",
  "audit_mode": "Audit mode",
  "bill_finder_mode": "Bill finder mode",
  "modes": "Modes",
  "recalculate_weeks": "Recalculate weeks",
  "last_bot_type": "Last bot type",
  "dNoHave": "Does not have",
  "queueBudgetManagement": "Queue budget management",
  "manage": "Manage",
  "icon": "Icon",
  "remove_from_budget": "Remove from budget",
  "out_budget": "Out budget",
  "in_budget": "In budget",
  "roolback": "Roolback",
  "change_budget": "Change budget",
  "not_waiting": "Not waiting",
  "bill_arrive": "Bill arrive",
  "bill_not_arrive": "Bill not arrive",
  "arrives": "Arrives",
  "waiting": "Waiting",
  "assing_massive_groups": "Assign massive groups",
  "deactivate_users": "Deactivate users",
  "active_users": "Active users",
  "remenber": "Remember",
  "assing_many_groups_warning": "Groups will be assigned to users who meet the filter range criteria.",
  "configure_uploader_permissions": "Configure uploader permissions",
  "expires_at": "Expires at",
  "pin": "Pin",
  "link": "Link",
  "section": "Section",
  "extra": "Extra",
  "old_provider": "Old provider",
  "new_provider": "New provider",
  "old_account": "Old account",
  "new_account": "New account",
  "registre_date": "Registre date",
  "sales_modality": "Sales modality",
  "change_marketer": "Change marketer",
  "user_make_change": "User make change",
  "market_type": "Market type",
  "assigned_real_date": "Assigned real date",
  "no_reclassification": "Do not use reclassification",
  "guru_type": "Guru type",
  "define_template": "Define template",
  "guruve_mode": "Guruve mode",
  "template": "Template",
  "optional": "Optional",
  "header_description": "Choose the type of multimedia content you will use for the Header of your message. (only one can be chosen)",
  "header_type": "Header type",
  "none": "None",
  "video": "Video",
  "body": "Message body",
  "body_description": "To customize the body of the message, enter the text you want to display. You can use variables to further customize the message. Variables are inserted as follows: {{variable number}}. Example: {{1}}. Make sure to assign variable numbers in ascending order so that the message is displayed correctly.",
  "characters": "Characters",
  "add_variable": "Add variable. Also, you can press the # key to display a menu with various options.",
  "actions": "Actions",
  "actions_description": "Define the type of action you want to show when the user receives the message.",
  "invoice_search": "Invoice search",
  "last_log": "Last log",
  "image": "Image",
  "comment_cannot_contain_quotes": "The comment cannot contain single or double quotes",
  "add_log": "Add log",
  "created_by": "Created by",
  "log_today": "Today's log",
  "ipp_ipc_date_configuration": "IPP/IPC date configuration",
  "telephony": "Telephony",
  "television": "Television",
  "con_ocr": "With OCR",
  "reason_user_charge": "User charge reason",
  "novelty_prevents_invoice_search": "Novelty prevents invoice search",
  "inconsistent_charger": "Inconsistent charger",
  "manageCredentials": "Manage credentials",
  "public_lighting_short": "P. Lighting",
  "bot_statistics": "Bot statistics",
  "bots_executed": "Bots executed",
  "exitosos": "Successful",
  "error_types": "Error types",
  "without_invoice": "Without invoice",
  "error_count": "Error count",
  "error_type": "Error type",
  "emails": "Emails",
  "generate_official_letter": "Generate official letter",
  "add_accounts": "Add accounts",
  "remove_accounts": "Remove accounts",
  "at_claiming": "At claiming",
  "remove_claim": "Remove claim",
  "guilty": "Guilty",
  "page_error": "Page error",
  "in_process": "In process",
  "total_attempts": "Total attempts",
  "view_all_details": "View all details",
  "completeds": "Completed",
  "consumption_calculation": "Consumption calculation",
  "created_provider_ticket": "Created provider ticket",
  "file_number": "File number",
  "created_from": "Created from",
  "open_invoice_history": "Open invoice history",
  "invoice_history": "Invoice history",
  "issue_date": "Issue date",
  "suspension_date": "Suspension date",
  "no_invoice_selected": "No invoice selected",
  "created_by_bulk_logs": "Created by bulk logs",
  "updateNoveltyAccount": "Edit account issue",
  "unit_cost": "Costo unitario",
  "tariff_recovery_cost": "Tariff recovery cost",
  "view_bills": "View bills",
  "uploader_error": "File whit errors",
  "uploader_error_subtitle": "Please check and modify the file information before uploading it again",
  "uploader_error_description": "The content you submitted has the following errors",
  "uploader_redirect_dashboard": "Return to dashboard",
  "status_report": "Status report",
  "upload_massive_file": "Upload massive file",
  "is_multiple": "Is multiple",
  "provider_inactive_since": "This provider has been inactive since",
  "selected_point": "Selected point",
  "not_selected": "Not selected",
  "zip_conditions": "To load the data correctly, make sure that: \n* The files in the ZIP match the template format. \n* The PDF files have the format country_code-phone_number.format. \nexample: 573XXXXXXXXX.pdf",
  "donwloadZip": "Download Zip",
  "total_rows": "Total rows",
  "total_ok": "Total ok",
  "total_error": "Total error",
  "last_update": "Last update",
  "job_id": "Job ID",
  "entity": "Entity",
  "proccess_and_ignore_errors": "Process and ignore errors",
  "this_process_can_take_a_while": "This process can take a while",
  "download_report": "Download report",
  "display_errors": "Display errors",
  "payment_information": "Payment information",
  "searching_information": "Searching information",
  "eguru": "Eguru",
  "visitado": "Visited",
  "edit_investments": "Edit investments",
  "additional_days": "Additional days",
  "add_issue": "Add issue",
  "account_issue": "Account issue",
  "invoice_issue": "Invoice issue",
  "internal_use_issue_vertebra": "Vertebra internal use issue",
  "budget_issue": "Budget issue",
  "closed_account_issue": "Closed account issue",
  "change_bill": "Change bill",
  "invoice_value_affecting_account_issue": "Account issue affecting invoice value",
  "updated_at": "Updated at",
  "total_cargada": "Total loaded",
  "total_digitadas": "Total typed",
  "total_no_facturadas": "Total unbilled",
  "increase_alerts": "Increase alerts",
  "decrease_alerts": "Decrease alerts",
  "average_alerts": "Average alerts",
  "total_alerts": "Total alerts",
  "view_alerts": "View alerts",
  "alert_type": "Alert type",
  "alert_status": "Alert status",
  "alert_month": "Alert month",
  "current_consumption": "Current consumption",
  "comparison_consumption": "Consumption comparison",
  "variation": "Variation",
  "return_to_average": "Return to average",
  "last_year_consumption": "Last year consumption",
  "alerts_chart": "Year consumption",
  "perform_tracking": "Perform tracking",
  "ticket": "Ticket",
  "registration_successful": "Registration successful",
  "mark_as_not_real": "Mark as not done",
  "mark_as_not_real_description": "Alert marked as not real",
  "marked_as_not_real": "Marked as not real",
  "is_real": "Is real",
  "digitation_error": "Typing error",
  "new_status": "New status",
  "file_will_arrive_soon": "The file will arrive in a few minutes to the email",
  "revert_closed": "Revert closed",
  "confirm_revert_status": "Are you sure you want to revert the status of this alert?",
  "last_tracking_date": "Last tracking date",
  "showing": "Showing",
  "hiding": "Hiding",
  "parent_accounts": "Parent accounts",
  "child_accounts": "Child accounts",
  "other_accounts": "Other accounts",
  "parent_and_child_accounts": "Parent and child accounts",
  "accounts_excluding_children": "Accounts excluding children",
  "accounts_excluding_parents": "Accounts excluding parents",
  "indifferent": "Indifferent",
  "all_companies": "All companies",
  "load_another_bill": "Load another bill",
  "view_meter_alerts": "View meter alerts",
  "meter_alerts": "Meter alerts",
  "increase": "Increase",
  "decrease": "Decrease",
  "net_balance": "Net balance",
  "maximum": "Maximum",
  "optimal_maximum": "Optimal maximum",
  "average": "Average",
  "minimum": "Minimum",
  "optimal_minimum": "Optimal minimum",
  "chart": "Chart",
  "view_full_screen": "View full screen",
  "last": "Last",
  "tracking": "Tracking",
  "parent_company": "Parent company",
  "next": "Next",
  "mark_as_not_real_question": "Are you sure you want to mark this alert as not real? This will also close it",
  "comparison": "Comparison",
  "average_1_year": "Average 1 year",
  "average_6_months": "Average 6 months",
  "insertFilePlaneClaro": "Insert Claro Plane File",
  "add_filters": "Add filters",
  "remove_filter": "Remove filter",
  "filter_limit_reached": "Filter limit reached",
  "save_filter_settings": "Save filter settings",
  "addUserCredentials": "Add user credentials",
  "save_filter_prompt": "If you accept, the current filter configuration will be saved. Each time you open this module for the first time, this configuration will be loaded",
  "ignore_atypical_consumption": "Ignore atypical consumption",
  "view_tracking": "View tracking",
  "scheduled_start": "Scheduled start",
  "scheduled_end": "Scheduled end",
  "total_lesson": "Total lesson",
  "progress": "Progress",
  "eguru_courses": "Eguru courses",
  "instances": "Instances",
  "category": "Category",
  "course_setup": "Course setup",
  "complete_all_fields": "Complete all fields",
  "customize_your_course": "Customize your course",
  "lessons": "Lessons",
  "course_name": "Course name",
  "course_description": "Course description",
  "course_image": "Course image",
  "course_chapters": "Course chapters",
  "drag_and_drop_to_reorder_chapters": "Drag and drop to reorder chapters",
  "active_course": "Active course",
  "no_chapters": "No chapters",
  "add_module": "Add module",
  "module_name": "Module name",
  "add_module_name": "Add module name",
  "add_chapter": "Add chapter",
  "compliance": "Compliance",
  "civil_liability": "Civil liability",
  "offer_sincerity": "Seriousness of Offer",
  "insurance_policy": "Insurance policy",
  "subservices": "Subservices",
  "total_lessons": "Total lessons",
  "contend": "Contend",
  "datail": "Datail",
  "last_access": "Last access",
  "instance_status": "Instance status",
  "on_progress": "On progress",
  "previous_status": "Previous status",
  "deactivate_account": "Deactivate account",
  "account_deactivated_successfully": "The account has been successfully deactivated",
  "active_period": "Active period",
  "case_title": "Title for the case",
  "create_odoo_ticket": "Create Odoo ticket",
  "configure_periods": "Configure periods",
  "add_date_range": "Add date range",
  "thermal_energy": "Thermal energy",
  "total_reviewed": "Total reviewed",
  "total_no_reviewed": "Total no reviewed",
  "no_reviewed_days": "No reviewed days",
  "not_review_in_a_last_two_days": "Not reviewed in a last two days",
  "subject": "Subject",
  "from_email": "From email",
  "has_attachment": "Has attachment",
  "attachment": "Attachment",
  "is_reviewed": "Is reviewed",
  "from": "From",
  "to": "To",
  "to_me": "To me",
  "replay_to": "Replay to",
  "bot_reviewed": "Bot reviewed",
  "comment": "Comment",
  "remove_review": "Remove review",
  "edit_review": "Edit review",
  "no_reviewed_day": "No reviewed day",
  "bot_not_reviewed": "Bot not reviewed",
  "total_lessons_completed": "Total lessons completed",
  "view_comments": "View comments",
  "total_completed": "Total completed",
  "in_progress": "In progress",
  "comments_instance": "Comments instance",
  "total_comments": "Total comments",
  "reload": "Reload",
  "comments_description": "Here you can find the comments for each lesson of the instance. If a lesson has no comments, nothing will appear to show.",
  "is_completed": "Is completed",
  "lesson": "Lesson",
  "contend_time": "Contend time",
  "id_video": "Id video",
  "upload_video": "Upload video",
  "total_pages": "Total pages",
  "add_lesson": "Add lesson",
  "courses": "Courses",
  "add_lessons_to_save": "Add lessons to save the course",
  "used_massive_log": "Used massive log",
  "searchBills": "Search bills",
  "bots_execution": "Bots execution",
  "export_policy": "Export policy",
  "measurement_location": "Measurement location",
  "max_budget": "Max budget",
  "max_value": "Max value",
  "digital_status": "Digital status",
  "total_accounts": "Total accounts",
  "total_payments": "Total payments",
  "execute_accounts_in_this_cluster": "Execute accounts in this cluster",
  "image_upload_flag": "Image is not mandatory in this mode. If an image is uploaded, it will only appear in the header of the text.",
  "upload_attachment": "Upload attachment",
  "tags": "Tags",
  "include_policy": "Include policy",
  "export_only_policy": "Export only policy",
  "attachments": "Attachments",
  "more": "More",
  "total_users": "Total users",
  "downloaded_files": "Downloaded files",
  "parents_only": "Parents only",
  "forum": "Requires comment to complete lesson.",
  "file_info": "File information",
  "file_name": "File name",
  "size_file": "File size",
  "upload_bill_description": "Upload bill to an account",
  "upload_bill_description_one": "Obtain a copy of the invoice that you want to upload to the account.",
  "upload_bill_description_two": "Verify the details of the account to which the invoice will be charged, including the account number, the company, and other information.",
  "account_info": "Account information",
  "see_last_bill": "See last bill",
  "last_bill": "Last bill",
  "automatically_upload": "Automatically upload",
  "upload_date": "Upload date",
  "see_upload_bills_view": "See uploaded bills",
  "see_bill": "See bill",
  "report_generated_successfully": "Report generated successfully",
  "executions_last_60_min": "Executions last 60 min",
  "available_executions": "Available executions",
  "go_back": "Go back",
  "perform_new_execution": "Perform new execution",
  "end": "End",
  "execute": "Execute",
  "select_accounts": "Select the accounts",
  "selected": "Selected",
  "are_you_sure_to_select": "Estás seguro de seleccionar",
  "bot_executed": "Bot executed",
  "bot_executed_message": "In a few moments the bots will start the search",
  "cancel_execution": "Cancel execution",
  "without_cluster": "Without cluster",
  "execute_cluster_and_without_cluster": "Execute cluster {{cluster}} and those without cluster",
  "remove_selection": "Remove selection",
  "last_execution": "Last execution",
  "max_characters_allowed": "Maximum {{charactersAllowed}} characters allowed",
  "successful_export": "Successful export",
  "info_will_arrive_in_minutes_to_email": "The information will arrive in a few minutes to the email {{email}}",
  "account_change": "Account change",
  "massive_novelties": "Massive novelties",
  "close_novelties": "Close novelties",
  "close_massive_novelties_confirm": "If you accept all the massive novelties of this execution, they will be closed",
  "add_massive_comments": "Add massive comment",
  "invoice_uploader": "Invoice uploader",
  "created_by_cluster": "Created by cluster",
  "are_you_sure": "Are you sure?",
  "if_chose_search_bills_tab_you_lose_email_filters": "If you choose the search bills tab, you will lose the email filters",
  "addDocuments": "Add documents",
  "document_type": "Document type",
  "view_document": "View document",
  "no_data": "No data",
  "add_document": "Add document",
  "legal_name": "Legal name",
  "nit": "NIT",
  "document_expiration_date": "Document expiration date",
  "operation_type": "Operation type",
  "invoices_not_uploaded_this_month": "Invoices not uploaded this month",
  "urgent": "Urgent",
  "see_actual_bill": "See actual bill",
  "bill_already_uploaded": "Bill already uploaded",
  "bill_already_uploaded_description": "The bill has already been uploaded to the account",
  "add_item": "Add item",
  "hide_senders": "Hide senders",
  "hide_senders_prompt": "Here you can hide emails from senders. Add the email address you want to exclude to the list.",
  "clear_filters": "Clear filters",
  "stats": "Stats",
  "success_message": "The list of hidden emails has been updated",
  "review_result": "Review result",
  "order_by": "Order by",
  "review_duplicate_bill": "Review duplicate bill",
  "non_billing_email": "Non billing email",
  "child": "Child",
  "id": "ID",
  "attachments_type": "Attachments type",
  "ticket_code": "Ticket code",
  "add_all_the_accounts": "Add all the accounts",
  "add_all_the_accounts_confirm": "If you accept, all accounts that comply with these filters will be added",
  "invoice_fields": "Invoice fields",
  "no_emails_selected": "No emails selected",
  "select_emails_to_view": "Select the emails to view",
  "no_emails_data": "No emails data",
  "inactive_account": "Inactive account",
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "custom": "Custom",
  "today": "Today",
  "TwoDays": "Two days",
  "SevenDays": "Seven days",
  "30Days": "30 days",
  "non_existent": "Non existent",
  "status_previously_listed": "Already loaded",
  "block_subject": "Block subject",
  "block_email": "Block email",
  "add_hidden_item": "Add hidden item",
  "empty_hidden_fields": "Please add at least one hidden item",
  "other_concepts": "Other concepts",
  "credit_note": "Credit note",
  "bots_will_be_executed_for": "Bots will be executed for",
  "original_sender": "Original sender",
  "of_them_will_run_as_doubles": "of them will run as doubles",
  "time_elapsed": "Time elapsed",
  "clean": "Clean",
  "visit_date": "Visit date",
  "orden": "Orden",
  "error_subtitle": "Opps! An error occurred",
  "error_description": "An error occurred while loading the data. Please try again later.",
  "visit_count": "Visit count",
  "initial_balance": "Initial balance",
  "last_invoice_uploaded": "Last invoice uploaded",
  "odoo_ticket_id": "Odoo ticket ID",
  "ticket_type": "Ticket type",
  "model": "Model",
  "company_assignment": "Company assignment",
  "company_assigned_successfully": "Company assigned successfully",
  "accounts_petitions": "Account petition",
  "company_name": "Company name",
  "marketer_name": "Marketer name",
  "marketer_email": "Marketer email",
  "has_bill": "Has bill",
  "first_send": "First send",
  "retries": "Retries",
  "last_attempt": "Last attempt",
  "uploaders": "Uploaders",
  "details": "Details",
  "open_extras": "Open extras",
  "one_month_comparation": "One month comparation",
  "one_month_variation": "One month variation",
  "one_month_variation_percent": "One month variation (%)",
  "three_months_comparation": "Three months comparation",
  "three_months_variation": "Three months variation",
  "three_months_variation_percent": "Three months variation (%)",
  "six_months_comparation": "Six months comparation",
  "six_months_variation": "Six months variation",
  "six_months_variation_percent": "Six months variation (%)",
  "annual_comparation": "Annual comparation",
  "annual_variation": "Annual variation",
  "annual_variation_percent": "Annual variation (%)",
  "alert_value_percent_max_limit_message": "The current value exceeds {{percent}}% of variation compared to the {{comparison}}",
  "annual_comparison": "Previous year",
  "one_month_comparison": "Previous month",
  "six_months_comparison": "6 months ago",
  "three_months_comparison": "3 months ago",
  "triggered_alerts": "Triggered alerts",
  "details_of": "Details of",
  "bill_alerts": "Bill alerts",
  "status_last_followup": "Status last followup",
  "pending": "Pending",
  "managed": "Managed",
  "current_value": "Current value",
  "seccess": "Success",
  "operationSuccessful": "Operation successful"
}
