{
  "login": "Entrar",
  "user": "Usuário",
  "password": "Senha",
  "enter": "ENTRAR",
  "recover": "Recuperar credenciais",
  "info_card": "Para suporte sobre o aplicativo SmartEco, por favor envie um email para",
  "user_not_valid": "Por favor, insira um usuário válido!",
  "user_password_valid": "Por favor, insira uma senha válida",
  "password_size": "A senha deve ter pelo menos 12 caracteres",
  "recovery_options": "Opções de recuperação",
  "form": "Formulário",
  "recover_user": "Recuperar usuário",
  "recover_password": "Recuperar senha",
  "cancel": "Cancelar",
  "label_email": "E-mail",
  "send_email": "Enviar email",
  "recover_password_capital_letter": "RECUPERAR SENHA",
  "name_user_capital_letter": "Nome de usuário",
  "field_required": "Este campo é obrigatório",
  "new_password_capital_letter": "NOVA SENHA",
  "recover_password_text": "Lembre-se de que a senha deve ter pelo menos 12 caracteres",
  "very_weak": "Muito fraca",
  "weak": "Fraca",
  "good": "Boa",
  "strong": "Forte",
  "verificate_password_capital_letter": "VERIFICAR SENHA",
  "update_password": "Atualizar senha",
  "all_rights_reserved": "Todos os direitos reservados",
  "version": "versão",
  "updatePassword": "Senha atualizada",
  "error_message1": "Ops, ocorreu um problema",
  "equal_fields": "Os campos devem coincidir",
  "shortcut": "ATALHOS",
  "msgIdiom": "Idioma alterado com sucesso",
  "language": "Idioma",
  "spanish": "Espanhol",
  "english": "Inglês",
  "Portuguese": "Português",
  "SignOff": "Sair",
  "management": "GERENCIAMENTO",
  "pane": "painel de ferramentas",
  "viewShortcuts": "Ver atalhos de teclado",
  "closeShortcuts": "Fechar atalhos de teclado",
  "beginning": "Início",
  "updateTable": "Atualizar tabela",
  "point": "Pontos",
  "account": "Contas",
  "groups": "Grupos",
  "domains": "Domínios",
  "doamin": "Domínio",
  "rates": "Cotações",
  "transactions": "Transações",
  "serviceProvider": "Prestador de serviços",
  "entities": "Entidades",
  "paymentMethods": "Métodos de pagamento",
  "money": "Dinheiro",
  "ResourceSources": "Fontes de recursos",
  "budget": "Orçamento",
  "bill": "Fatura",
  "Payments": "Pagamentos",
  "audits": "Auditorias",
  "loads": "Cargas",
  "meter": "Medidor",
  "search": "Procurar",
  "reportDownloads": "Downloads de relatórios",
  "company": "Empresa",
  "roles": "Funções",
  "invoiceDashboard": "Dashboard de faturas",
  "dashboardPayments ": "Dashboard de pagamentos",
  "Vertebots": "Vertebots",
  "serviceProviders": "Provedores de serviços",
  "concepts": "Conceitos",
  "name": "Nome",
  "copy": "Copiar",
  "copied": "Copiado",
  "abbreviation": "Abreviação",
  "abb": "Abrev",
  "type": "Tipo",
  "identificationBusiness": "NIT",
  "dv": "DV",
  "service": "Serviços",
  "movii": "Movii",
  "voucher": "Comprovante",
  "email": "email",
  "yes": "Sim",
  "no": "Não",
  "delete": "Excluir",
  "edit": "Editar",
  "create": "Criar",
  "searchs": "Pesquisar",
  "state": "Estado",
  "activate": "Ativar",
  "deactivate": "Desativar",
  "activeTable": "Ativo",
  "inactive": "Inativo",
  "subproject": "Subprojeto",
  "extras": "Extras",
  "seeExtras": "Ver extras",
  "extrasOf": "EXTRAS DE ",
  "addEquivalence": "Adicionar equivalência",
  "department": "Departamento",
  "city": "Cidade",
  "dateFirst": "Data de início",
  "dateEnd": "Data final",
  "total": "Total",
  "record": "Registros",
  "detail": "Detalhe",
  "remember": "Lembrar",
  "textAlertProvider": "Para poder administrar as notificações, a conta deve ser editada e selecionar a opção de multi notificação.",
  "deleteTextAlertProvider": "O provedor de serviço foi excluído com sucesso.",
  "upateTextAlertProvider": "Provedor de serviço atualizado para:",
  "updateNotificationProviderActivate": "Envio de notificações ativado",
  "updateNotificationProviderDesactivate": "Envio de notificações desativado",
  "manageNotifications": "Gerenciar notificações",
  "activatePetitionBill": "Ativar envio de petição de fatura",
  "deactivatePetitionBill": "Desativar envio de petição de fatura",
  "viewProviderInformation": "Ver informações do provedor",
  "close": "Fechar",
  "short": "Curto",
  "multiNotification": "MULTI NOTIFICAÇÃO",
  "phone": "Telefone",
  "fieldRequiredNumber": "Este campo aceita apenas números",
  "fieldRequiredDv": "Deve digitar o número de digitação",
  "fielRequiredType": "Deve selecionar um tipo",
  "typeService": "Tipo de Serviço",
  "fieldRequiredTypeService": "Deve selecionar o tipo de serviço",
  "proffSeding": "ENVIO DE COMPROVANTE",
  "fieldRequiredOption": "Deve selecionar uma opção",
  "notificationEmail": "Notificação por e-mail",
  "regularPayment": "PAGAMENTO REGULAR",
  "regulatedPayment": "Pagamento regulado",
  "reactiveCharge": "Cobrança por reativa",
  "fieldNitRequired": "Deve digitar um NIT",
  "alertEntityDelete": "A entidade foi excluída com sucesso.",
  "nameEntity": "Nome da entidade",
  "stateEnity": "Estado da entidade",
  "notificationDeleteDomains": "O domínio foi excluído com sucesso.",
  "father": "Pai",
  "dNotHave": "Não possui",
  "domainCreate": "Domínio criado",
  "alertDomainCreate": "Seu domínio foi criado com sucesso",
  "selectionFather": "Deve selecionar um pai",
  "add": "Adicionar",
  "removeFather": "remover pai",
  "alertPaymentDelete": "O método de pagamento foi excluído com sucesso.",
  "addEquivalenceA": "Adicionar equivalência a",
  "updateConcept": "Editar conceito",
  "alertDeleteMeter": "Tem certeza de que deseja excluir este medidor?",
  "deleteMeter": "Medidor excluído com sucesso",
  "updateMeters": "Medidor modificado com sucesso",
  "addMeterNews": "Medidor criado com sucesso",
  "subprojects": "Subprojetos",
  "disableSubProjectText": "Desabilitar subprojetos caso não se aplique ao tipo de serviço",
  "deleteTarFeeAlert": "Tem certeza de que deseja excluir esta tarifa?",
  "alertDeleteFee": "Tarifa excluída com sucesso",
  "createRate": "Criar tarifa",
  "updateRate": "Editar tarifa",
  "viewFiles": "Ver Arquivo",
  "noFiles": "Sem arquivos",
  "voltageLevel": "Nível de tensão",
  "propiety": "Propriedade",
  "detailOf": "Detalhe de",
  "recolection": "Coleta",
  "publicToilet": "Banheiro público",
  "complementary": "Complementar",
  "sumptuary": "Sumptuário",
  "energeticValue": "Valor Energético",
  "deleteUserQuestion": "Tem certeza de que deseja excluir este usuário?",
  "deleteUserAlert": "Usuário excluído com sucesso",
  "updateUserQuestion": "Tem certeza de que deseja alterar o estado deste usuário?",
  "updateUserAlert": "Estado do usuário modificado",
  "nickName": "Apelido",
  "lastAdmission": "Último ingresso",
  "verified": "Verificado",
  "notVerified": "Verificação pendente pelo usuário",
  "updateState": "Alterar estado",
  "assign": "Atribuir",
  "access": "Acessos",
  "office": "Escritórios",
  "notification": "Notificações",
  "download": "Baixar",
  "accessUpdatedAlert": "Acessos atualizados com sucesso",
  "group": "Grupo",
  "result": "resultado",
  "userAccessRepeat": "Este usuário já possui todos os grupos atribuídos",
  "Ops": "Ops",
  "valueRequired": "Este valor é obrigatório",
  "regionals": "Regionais",
  "typeUser": "Tipo de usuário",
  "allRegionals": "Todas as regionais",
  "selectionAll": "Selecionar tudo",
  "deleteAssign": "Excluir atribuições",
  "textUserAssgin": "Para continuar, selecione uma empresa",
  "assignReportBi": "Atribuir Relatórios de BI",
  "report": "Relatórios",
  "all": "Todos",
  "url": "URL",
  "reportDownload": "Download de Relatórios",
  "assignReportDownload": "Atribuição de download de relatórios",
  "alertOfficeRegister": "Escritórios registrados com sucesso",
  "permissions": "Permissões",
  "removed": "Removida",
  "saveChanges": "Salve para salvar as alterações.",
  "save": "Salvar",
  "alertMessageNotifications": "Todas as notificações serão desativadas.",
  "alertConfigNotifications": "Configurações de notificações atualizadas",
  "configNotification": "Configurar notificações",
  "activateConfirm": "Ativada",
  "deactivateConfirm": "Desativada",
  "messageNotifications": "Todas as notificações da empresa foram desativadas",
  "notificationBillCompany": "Notificar se a fatura da empresa",
  "wasLoaded": "foi carregada.",
  "notificationVoucherCompany": "Notificar se o comprovante de pagamento da empresa",
  "itRejected": "foi rejeitada.",
  "notifyIf": "Notificar se",
  "billAndVouchers": "carregar fatura e/ou comprovantes.",
  "addRolSave": "O papel já foi adicionado para esta empresa",
  "userSaveUpdated": "Usuário atualizado com sucesso",
  "userGetInto": "Por favor, insira um nome!",
  "lastName": "Sobrenome",
  "lastNameUsergetInto": "Por favor, insira um sobrenome!",
  "emailError": "Por favor, insira um e-mail válido!",
  "rolAplication": "Função do aplicativo",
  "assignRolCompany": "Atribuir funções por empresa",
  "generatePassword": "Gerar senha",
  "pleasePassworValidate": "Por favor, insira uma senha válida!",
  "users": "Usuários",
  "rolDeleteAlert": "Tem certeza de que deseja excluir este papel?",
  "rolDeleteAlertConfirm": "Papel excluído com sucesso",
  "updateRolAlert": "Tem certeza de que deseja alterar o estado deste papel?",
  "updateRolAlerModif": "Estado do papel modificado",
  "cancelAlert": "Cancelada",
  "actionUpdateCancel": "Ação de mudança de estado cancelada",
  "rolModif": "Papel modificado com sucesso",
  "createRol": "Papel criado com sucesso",
  "pointDeleteAlert": "O ponto foi excluído com sucesso.",
  "notVerificed": "",
  "admiPoint": "Administrar ponto",
  "viewInfoPoint": "Ver informações do ponto",
  "viewHistoryPoint": "Ver histórico de pontos",
  "generateCertificed": "Gerar certificado",
  "order": "Ordem",
  "direction": "Direção",
  "others": "Outros",
  "location": "Localização",
  "power": "Potência",
  "amount": "Quantidade",
  "noData": "Sem dados",
  "enterPointName": "Você deve digitar um nome do ponto",
  "namePoint": "Nome do ponto",
  "fullName": "Nome completo",
  "enterCityName": "Você deve digitar o nome da cidade",
  "enterDptoName": "Você deve digitar o departamento",
  "enterLocationName": "Você deve digitar um endereço",
  "paymentNotification": "Notificação de pagamento",
  "sendNotificationPayment": "Enviar notificação de pagamento ao pagamento",
  "pointState": "Estado do ponto",
  "officeCode": "Código do escritório",
  "entrerCodOffice": "Você deve digitar o código do escritório",
  "numberEmployer": "Nº de funcionários",
  "area": "Área",
  "latitude": "Latitude",
  "fielObligatoryLatitude": "Este campo é obrigatório se você inserir uma longitude",
  "length": "Longitude",
  "fieldObligatoryLength": "Este campo é obrigatório se você inserir uma latitude",
  "namePonitShort": "Você deve digitar um nome curto do ponto",
  "updateStateExit": "O estado da conta foi alterado com sucesso",
  "dateFirstOperation": "Data de início de operação",
  "dateSelection": "Você deve selecionar uma data",
  "dateUpdateText": "Data de atualização do estado",
  "infoPoint": "Informações do ponto",
  "employer": "Empregados",
  "codOffice": "Código do escritório",
  "allowsNotifications": "Permite notificações",
  "update": "Alterar",
  "shedule": "Horários",
  "enterDate": "Insira uma data",
  "enterDateEnd": "Insira uma data final",
  "accountDelete": "A conta foi excluída com sucesso.",
  "editAuthorizations": "Editar autorizações",
  "UploadArchive": "Enviar arquivo plano",
  "addNovelty": "Adicionar novidade",
  "viewNovelty": "Ver",
  "viewInfoAccount": "Ver informações da conta",
  "viewHistoryAcoount": "Ver histórico de contas",
  "number": "Número",
  "dates": "Datas",
  "Cleanliness": "Limpeza",
  "Gas": "Gás",
  "water": "Água",
  "enegy": "Energia",
  "sewerage": "Esgoto",
  "streetLighting": "Iluminação pública",
  "novelty": "Novidades",
  "authorized": "Autorizado",
  "paymentInfoCompleted": "Info. de pagamento completa",
  "newComercio": "Nova comerciante desde",
  "previusAccount": "Conta anterior",
  "inactiveSince": "Inativo desde",
  "authorizedPayment": "Pagamento autorizado",
  "dataAccount": "Dados da conta",
  "codVerification": "Código de verificação",
  "enterNumberAccount": "Deve inserir um número de conta",
  "numberMeter": "Número do medidor",
  "emailNotification": "Notificação por e-mail",
  "markertType": "Tipo de mercado",
  "infoLocation": "Informação de localização",
  "selectionRegional": "Deve selecionar a regional",
  "selectionPoint": "Deve selecionar um ponto",
  "selectionService": "Deve selecionar o provedor de serviço",
  "dateEmition": "Data de emissão",
  "dateStimedPayment": "Data estimada de pagamento",
  "timelyPaymentDate": "Data de pagamento oportuno",
  "initialBillingDate": "Data inicial de faturamento",
  "dateInitial": "Data inicial",
  "BudgetsAndPayments": "Orçamentos e pagamentos",
  "frequency": "Frequência",
  "accountFather": "Conta pai",
  "socioeconomicLevel": "Nível socioeconômico",
  "paymentVertebra": "Pagamento de vértebra",
  "authorizedForPayment": "Autorizado para pagamento",
  "clientUploadBill": "Cliente faz o upload da fatura",
  "serviceDate": "Datas do serviço",
  "StreetLighting": "Iluminação pública",
  "selectionFrecuency": "Deve selecionar a frequência",
  "uploadFileOk": "Upload do arquivo plano realizado com sucesso",
  "dateBill": "Data da fatura",
  "dateReal": "Data real",
  "billingPeriod": "Período de faturamento",
  "dateBillDate": "Data da fatura",
  "suspencionDate": "Data de suspensão",
  "period": "Período",
  "upSupport": "Enviar suporte",
  "fieldRequired": "Campo obrigatório!",
  "clickUploadVoucher": "Clique ou arraste o comprovante",
  "uploadFile": "Enviar arquivo",
  "changeOfMarketer": "Mudança de comerciante",
  "numberAccount": "Número da conta",
  "estimatedDateOFIssue": "Data estimada de emissão",
  "tymelyPaymentDate": "Data de pagamento oportuno",
  "infoPaymentVertebra": "Informações de pagamento de vértebra",
  "allowPayment": "Permitir pagamento",
  "typeNovelty": "Tipo de novidade",
  "comentary": "Comentário",
  "observation": "Observação",
  "comentaryNovelty": "Comentário da novidade",
  "newNovelty": "Criar novidade",
  "shortName": "Nome curto",
  "allowPaymentNotificatios": "Permitir notificações de pagamento",
  "inactivationDate": "Data de inativação",
  "propertyLevel": "Nível de propriedade",
  "coordinates": "Coordenadas",
  "dateOfIssue": "Data de emissão",
  "numberVerification": "Número de verificação",
  "serviceName": "Nome do serviço",
  "lastBill": "Última fatura",
  "selectRecordTheChange": "Selecione um registro para ver as alterações",
  "export": "Exportar",
  "userMakingtheChange": "Usuário que fez a alteração",
  "operationDate": "Data da operação",
  "alertDeleteRates": "Tem certeza de que deseja excluir esta tarifa?",
  "rateDeleteOk": "Tarifa excluída com sucesso",
  "closeNovelty": "Fechar novidade",
  "addComentary": "Adicionar comentário",
  "deleteGroupAlert": "Grupo excluído com sucesso",
  "groupCode": "Código do grupo",
  "groupName": "Nome do grupo",
  "errorNameRegional": "Deve inserir um nome de regional",
  "selectionStateError": "Deve selecionar um estado",
  "stateGroup": "Estado do grupo",
  "transaction": "Transações",
  "resourceSource": "Origem de recurso",
  "BillToPay": "Contas a pagar",
  "newTransaction": "Criar transação",
  "closeTransaction": "Fechar transação",
  "concilationsView": "Ver conciliações",
  "uploadFileConcilation": "Enviar arquivo de conciliação",
  "operationType": "Tipo de operação",
  "bankVerification": "Verificação bancária",
  "menu": "Menu",
  "transactionVerified": "Transação verificada",
  "amounts": "Montantes",
  "initialBalance": "Saldo inicial",
  "endBalance": "Saldo final",
  "responsible": "Responsável",
  "reconcile": "Conciliar",
  "viewInfoBill": "Ver informações da fatura",
  "detailBill": "Detalhe da fatura",
  "noveltyDeleteOk": "Novidade excluída com sucesso",
  "comentaryDeleteOk": "Comentário excluído com sucesso",
  "sourceIdResource": "ID da origem do recurso",
  "sourceNameOfTheresource": "Nome da origem do recurso",
  "sourceIdCompany": "ID da origem da empresa",
  "sourceNameOfTheCompany": "Nome da origem da empresa",
  "place": "Local",
  "contactAccount": "Conta de contato",
  "marketer": "Comerciante",
  "numberBill": "Número da fatura",
  "typeRegister": "Tipo de registro",
  "totalToPay": "Total a pagar",
  "paymentMade": "Pagamento efetuado",
  "dateOfBills": "Data da fatura",
  "dateSuspencion": "Data de suspensão",
  "paymentDate": "Data de pagamento",
  "viewBill": "Ver fatura",
  "updateCompany": "Empresa atualizada com sucesso",
  "updateQuuestionCompany": "Tem certeza de que deseja alterar o estado desta empresa?",
  "stateCompanyAlert": "Estado da empresa alterado",
  "assignNotification": "Atribuir notificações",
  "newCompany": "Criar empresa",
  "editCompany": "Editar empresa",
  "manager": "Gerente",
  "change": "Alterar",
  "updateTransaction": "Editar transação",
  "reconciliations": "Conciliações",
  "manualReconciliations": "Conciliações manuais",
  "originOfMoney": "Origem do dinheiro",
  "paymentMethod": "Método de pagamento",
  "paymentReference": "Referência de pagamento",
  "valuePaid": "Valor pago",
  "voucherNumber": "Número do comprovante",
  "enablePaymentVertebra": "Habilitado para pagamento vertebral",
  "enableForPayment": "Habilitado para pagamento",
  "seeProof": "Ver comprovante",
  "creation": "Criação",
  "billLoad": "Carregamento da fatura",
  "typedBy": "Digitado por",
  "approvedBy": "Aprovado por",
  "voucherUpload": "Carregamento do comprovante",
  "conceptsForConsumption": "Conceitos para consumo",
  "toiletPrice": "Preço do banheiro",
  "contributionForTheToilet": "Contribuição para o banheiro",
  "totalPaidInToilet": "Total pago no banheiro",
  "activeEnergyCurrentMeasurement": "Medição atual de energia ativa",
  "previusEnergyCurrentMeasurement": "Medição anterior de energia ativa",
  "activePowerEnergy": "Fator de energia ativa",
  "activeEnergyConsumption": "Consumo de energia ativa",
  "activeEnergyRate": "Taxa de energia ativa",
  "activeEnergyPrice": "Preço da energia ativa",
  "activeEnergyContribution": "Contribuição de energia ativa",
  "currentMesurementOrReactiveEnergy": "Medição atual de energia reativa",
  "previusMesurementOrReactiveEnergy": "Medição anterior de energia reativa",
  "reactivePowerfactor": "Fator de energia reativa",
  "reactiveEnergyComsuption": "Consumo de energia reativa",
  "reactiveEnergyrate": "Taxa de energia reativa",
  "reactiveEnergiPrice": "Preço da energia reativa",
  "contributionForReactiveEnergy": "Contribuição para energia reativa",
  "hourOfInterruption": "Horas de interrupção",
  "totalPaidEnergy": "Total pago de energia",
  "currentMeasureOfAqueduct": "Medição atual do aqueduto",
  "previusMeasureOfAqueduct": "Medição anterior do aqueduto",
  "waterComsuption": "Consumo de água",
  "basicAqueductRate": "Taxa básica de aqueduto",
  "coplementaryAqueductRate": "Taxa complementar de aqueduto",
  "suntaryAqueductRate": "Taxa de aqueduto voluntário",
  "basicFixedPriceAqueduct": "Preço fixo básico do aqueduto",
  "complementaryFixedPriceAqueduct": "Preço fixo complementar do aqueduto",
  "fixedPriceQueduct": "Preço fixo do aqueduto",
  "aqueductContribution": "Contribuição do aqueduto",
  "contributionForAqueductFixedPrice": "Contribuição para o preço fixo do aqueduto",
  "basicSewerRate": "Taxa básica de esgoto",
  "sumpturySewerRate": "Taxa sumptuária de esgoto",
  "basicFIxedPriceAqueduct": "Preço fixo básico do aqueduto",
  "coplementarySewerRate": "Taxa complementar de esgoto",
  "coplementaryFixedPriceAqueduct": "Preço fixo complementar do aqueduto",
  "fixedPriceSumptaryAqueduct": "Preço fixo sumptuário do aqueduto",
  "seweragePrice": "Preço do esgoto",
  "fixedPriceAqueduct": "Preço fixo do aqueduto",
  "fixedPriceSewerage": "Preço fixo do esgoto",
  "contributionForSewerage": "Contribuição para esgoto",
  "contributionForFixedSeweragePrice": "Contribuição para o preço fixo do esgoto",
  "pricePaidForTheAqueductP": "Preço pago pelo aqueduto",
  "pricePaidForTheAqueduct": "Preço pago pelo aqueduto",
  "totalOther": "Total de outros",
  "voluntaryContribution": "Contribuição voluntária",
  "otherToilet": "Outros banheiros",
  "observations": "Observações",
  "noComment": "Sem comentários",
  "deleteComentaryAlertBill": "Você está prestes a excluir um comentário da fatura",
  "notComentary": "Sem comentários",
  "deleteNoveltyBillAlert": "Você está prestes a excluir uma novidade da fatura",
  "notNovelty": "Sem novidades",
  "tracing": "Rastreamento",
  "by": "por",
  "at": "às",
  "sorceId": "ID de Origem",
  "source": "Origem",
  "destinityID": "ID de Destino",
  "destinity": "Destino",
  "date": "Data",
  "reference": "Referência",
  "transferAmount": "Valor transferido",
  "resource": "Recurso",
  "transactionInfo": "Informações da transação",
  "refrenceInfo": "Informações de referência",
  "operationBillAsiigned": "A operação não possui uma fatura atribuída.",
  "paymentInfo": "Informações de pagamento",
  "auditInfo": "Informações de auditoria",
  "notNoveltyAdd": "Nenhuma novidade adicionada",
  "notConceptsAdd": "Nenhum conceito adicionado",
  "selectionDate": "Você deve selecionar uma data",
  "transactioExits": "A transação foi concluída com sucesso",
  "startDateOperation": "Data de início da operação",
  "errorFileUpload": "Erro no carregamento do arquivo.",
  "imageSizeErr": "a imagem não deve ter mais de 20 MB",
  "transactionEditOk": "Transação editada com sucesso",
  "transactionNewOk": "Transação criada com sucesso",
  "nameOperation": "Nome da operação",
  "numberBillEnter": "Digite o número da fatura",
  "numberReferencia": "Número de referência",
  "lockedReturn": "Bloquear retorno",
  "selectionRatesFile": "Clique ou arraste o comprovante da transação",
  "uploadVaucher": "Carregar comprovantes",
  "uploadFileBIll": "Você deve enviar uma imagem da fatura",
  "editRates": "Editar transações",
  "newRates": "Criar transação",
  "returnRates": "Retornar transação",
  "fileUploadOk": "Arquivo carregado com sucesso",
  "uploadReconciliation": "Carregar conciliação",
  "reportNewOk": "Relatório criado com sucesso",
  "yourReporNewOk": "Seu relatório foi criado com sucesso",
  "manualReconciliation": "Conciliação manual",
  "recolitianOk": "Conciliação realizada com sucesso",
  "totallySelection": "Total selecionado",
  "reconconciliationManully": "Realizar conciliação manual",
  "successConsoliditedOperation": "Operação consolidada com sucesso",
  "send": "Enviar",
  "addFounds": "Adicionar fundos",
  "editFounds": "Editar fundos",
  "updateQuestion": "Tem certeza de que deseja alterar o estado?",
  "stateResourseOrigen": "Estado da origem do recurso",
  "balance": "Saldo",
  "incomeMonth": "Receita do mês",
  "expensesMonth": "Despesas do mês",
  "seeDetails": "Ver detalhes",
  "viewBalanceHistory": "Ver histórico de saldos",
  "foundAddSuccefully": "Fundo adicionado com sucesso",
  "balances": "Saldos",
  "uploadProof": "Enviar comprovantes",
  "proodUploadFile": "Clique ou arraste o comprovante da transação",
  "detailRates": "Detalhes de uma transação",
  "lastClosing": "Último fechamento",
  "newResourceSource": "Criar origem do recurso",
  "editResourceSource": "Editar origem do recurso",
  "moveFounds": "Mover fundos",
  "resourceWithoutNumber": "Origem do recurso sem número",
  "toAccept": "Aceitar",
  "deleteResourceSourceAlert": "Tem certeza de que deseja excluir esta origem de recurso?",
  "resourceSourceDeleteOk": "Origem de recurso excluída com sucesso",
  "styateResourceSourceUpdate": "Estado da origem do recurso alterado com sucesso",
  "assignResourceSource": "Atribuir origem de recurso",
  "pricipally": "Principal",
  "children": "Filhos",
  "income": "Receitas",
  "expenses": "Despesas",
  "alert": "Alertas",
  "entitie": "Entidade",
  "assignAccess": "Atribuir Acessos",
  "sourceResourceOk": "Origem do recurso criada com sucesso",
  "resourceHierachy": "Hierarquia do recurso",
  "foundTransfer": "Transferência de fundos realizada com sucesso",
  "originResource": "Origem do recurso",
  "transactionDetail": "Detalhes da transação",
  "region": "Região",
  "reg": "Reg",
  "janury": "Janeiro",
  "jan": "Jan",
  "febraury": "Fevereiro",
  "feb": "Fev",
  "march": "Março",
  "mar": "Mar",
  "april": "Abril",
  "apr": "Abr",
  "May": "Maio",
  "mays": "Mai",
  "june": "Junho",
  "july": "Julho",
  "jun": "Jun",
  "jul": "Jul",
  "august": "Agosto",
  "aug": "Ago",
  "september": "Setembro",
  "sep": "Set",
  "october": "Outubro",
  "oct": "Out",
  "november": "Novembro",
  "nov": "Nov",
  "december": "Dezembro",
  "dec": "Dez",
  "general": "Geral",
  "gas": "Gás",
  "admin": "Admin",
  "tFija": "T. Fixa",
  "tMovil": "T. Móvel",
  "tv": "Televisão",
  "stateAccount": "Estado da conta",
  "supplier": "Fornecedor",
  "restoreInvoice": "Restaurar fatura",
  "seeInvoiceAndReceipt": "Ver fatura e recibos",
  "uploadfBill": "Carregar fatura",
  "unpaidInvoiceForVertebra": "Fatura não paga pela Vértebra",
  "NotDataViews": "Não há dados para mostrar",
  "accountPointBlock": "Conta ou ponto bloqueados",
  "retoreInvoicePeding": "Restaurar fatura pendente",
  "restore": "Restaurar",
  "support": "Suporte",
  "forPayment": "Para pagamento",
  "tracklingUpdateOk": "Rastreamento atualizado com sucesso",
  "noveltyPayment": "Novidades de pagamentos",
  "follows": "Seguimentos",
  "yourRemoveBillFollow": "Você está prestes a remover um acompanhamento da fatura",
  "revised": "Revisada",
  "revisedAccount": "Conta revisada",
  "billUpdatedAlertPunding": "Tem certeza de que deseja restaurar esta fatura para PENDENTE?",
  "areYouSure": "Você tem certeza?",
  "billUpdateAlertFingered": "Você está prestes a alterar o estado da sua fatura para DIGITADA",
  "paymentNotAuthorized": "Pagamento não autorizado",
  "month": "Mês",
  "editDateAssing": "Editar data atribuída",
  "statePaynment": "Estado de pagamento",
  "plataformNotRegister": "Não registrado na plataforma",
  "stateFingered": "Estado em digitação",
  "companyes": "Empresas",
  "statics": "Estatísticas",
  "rejectBillAlert": "Tem certeza de que deseja restaurar a fatura rejeitada?",
  "billRestoredOk": "Fatura restaurada com sucesso",
  "budgetaryAddAccount": "Tem certeza de que deseja adicionar esta conta ao orçamento?",
  "accountAddOk": "Conta adicionada com sucesso ao orçamento",
  "isSecureBill": "Tem certeza de que deseja revisar esta fatura?",
  "accountRevised": "Contas revisadas",
  "isSecurePaymenChildren": "Tem certeza de que deseja propagar o pagamento nas faturas filhas?",
  "paymentOkAlert": "Pagamento propagado com sucesso",
  "viewVoucher": "Ver Recibo",
  "reAsign": "Reatribuir",
  "addNoveltyAccount": "Adicionar novidade de conta",
  "restoredRechazed": "Restaurar Rejeitada",
  "addBudget": "Adicionar ao orçamento",
  "changeBudget": "Alterar orçamento",
  "Outof_budget": "Fora do orçamento",
  "autorizedPayment": "Autorizar pagamento",
  "addNewInvoce": "Adicionar novidade de fatura",
  "addNoveltyToTracking:": "Adicionar novidade ao acompanhamento",
  "slopes": "Pendentes",
  "totallyPayment": "Total a pagar",
  "paid": "Pagadas",
  "totalPaid": "Total pago",
  "assigns": "Atribuído",
  "infoBill": "Informação de fatura",
  "billPendient": "Faturas Pendentes",
  "amountPendient": "Valor Pendentes",
  "porcetain": "Percentagem",
  "rulesAmount": "Regras de Montante",
  "providers": "Prestadores",
  "new": "Novo",
  "assignMissing": "Atribuir Faltantes",
  "redistribute": "Redistribuir",
  "uploadChart": "Carregando o gráfico",
  "updateDate": "Os dados foram atualizados.",
  "and": "e",
  "ejectBoots": "Execução de bots",
  "paymentMarketerService": "P. Serviço mais pagos",
  "digitBillAlerts": "Digitação, Faturas Digitadas Alertas",
  "energyConsumerd": "Energia consumida",
  "lastUpdated": "Última vez atualizado:",
  "value": "Valor",
  "status": "Estado",
  "vertebots": "Vertebots",
  "downloadBill": "Downloads de faturas",
  "infovertebots": "Informação de vertebots",
  "ejecstBoots": "Executar vertebots",
  "idFather": "Id Pai",
  "idOFCompany": "Id da Empresa",
  "totalFather": "Total Pai",
  "noStartDate": "Sem data de início",
  "noEndDate": "Sem data de término",
  "completed": "Completadas",
  "mistake": "Erros",
  "viewDetailOperation": "Ver Detalhe operação",
  "displayInformationAbout": "Exibir informações sobre",
  "hideInformationFrom": "Ocultar informações de",
  "displayLogOf": "Exibir log de",
  "hideLogFrom": "Ocultar log de",
  "uploadedSuccessfully": "carregado com sucesso",
  "whenTryingAlert": "ao tentar enviar o arquivo, tente novamente erro",
  "vertebotStartOk": "Vertebot iniciado com sucesso",
  "ocrEjectOk": "OCR executado com sucesso",
  "oneMeter": "Um medidor",
  "allMeter": "Todos os medidores",
  "youMustUploaderFile": "Você deve carregar um arquivo",
  "clickUploadFile": "Clique ou arraste o arquivo",
  "selectionDateMeter": "Selecione uma data",
  "selectionDateDownloadBullAndAccount": "Selecione uma data para baixar todas as faturas de uma conta",
  "treeDownload": "Árvore de download",
  "attempts": "Tentativas",
  "noBillingDate": "Sem data de faturamento",
  "lastDateAttempts": "Última data de tentativa",
  "noDateAttemptsColumns": "Não há data de última tentativa",
  "datUpdate": "Data de atualização",
  "notDateUpdate": "Sem data de atualização",
  "dateNew": "Data de criação",
  "notDateNew": "Sem data de criação",
  "selectionOne": "Selecione uma",
  "selectionOptionViews": "Selecione uma opção para visualizar os painéis de consumo",
  "rememberOption": "Lembre-se de que esta opção pode demorar um pouco",
  "newLoad": "Criar carga",
  "configurateAvanzedLoad": "Configuração avançada de carga para tipo de medidor",
  "updatesMeters": "Editar Medidor",
  "stateMeterUpdate": "Estado do medidor modificado",
  "actionStateCanceled": "Ação de mudança de estado cancelada",
  "infoLoad": "Informações da carga",
  "createAlertMeter": "Criar alertas de medidor",
  "description": "Descrição",
  "meteer": "Medidor",
  "typeMeter": "Tipo de medidor",
  "viewUrl": "Ver URL",
  "configAvanzed": "Config. Avançada",
  "viewDetail": "Ver detalhe",
  "loadUpdateOk": "Carga modificada com sucesso",
  "newLoadOk": "Carga criada com sucesso",
  "meterss": "Medidores",
  "inserDescriptionLoad": "Insira uma descrição para a carga...",
  "uploadSupport": "Enviar suporte",
  "textRepeatVoucherUpload": "Já existe um comprovante carregado, se enviar um novo, o comprovante existente será excluído.",
  "instalation": "Instalação",
  "multiplicationFactor": "Fator de multiplicação",
  "relationCTS": "Relação CTS",
  "typeOfMeasure": "Tipo de medida",
  "circuitType": "Tipo de circuito",
  "powerFactor": "Fator de potência",
  "voltage": "Voltagem",
  "createAlert": "Criar alerta",
  "editAlert": "Editar alerta",
  "alertDeleteAlert": "Tem certeza de que deseja excluir este alerta?",
  "alertDeteleOk": "Alerta excluído com sucesso",
  "var": "Variável",
  "temp": "Tempo",
  "alertUpodateOk": "Alerta atualizado com sucesso",
  "alertNewOk": "Alerta criado com sucesso",
  "pleaseInsertVar": "Por favor insira uma variável!",
  "pleaseInsertValue": "Por favor insira um valor",
  "pleaseInsertTemp": "Por favor insira um tempo válido",
  "nameOfCompany": "Nome da empresa",
  "idGroup": "ID do Grupo",
  "nameOfGroup": "Nome do Grupo",
  "idPoint": "ID do Ponto",
  "nameOfPoint": "Nome do Ponto",
  "idMeter": "ID do Medidor",
  "nameMeter": "Nome do Medidor",
  "viewResource": "Ver Recurso",
  "idOfLoad": "ID da Carga",
  "nameOfLoad": "Nome da Carga",
  "infoAvanzedLoad": "Informações avançadas da carga",
  "operationInfo": "A operação não possui informações",
  "newMeter": "Criar Medidor",
  "avanzedConfig": "Configuração avançada",
  "ediMeter": "Editar Medidor",
  "infoMeter": "Informações do Medidor",
  "updatePasswordMeter": "Alterar senha dos medidores",
  "serial": "Serial",
  "protocol": "Protocolo",
  "dateInstalation": "Data de Instalação",
  "lastReport": "Último Relatório",
  "serialOfmMeter": "Serial do Medidor",
  "deviceName": "Nome do Dispositivo",
  "errorAddinfo": "Erro ao adicionar informações",
  "codNameLastAdd": "O código ou o nome do banco de dados já foram adicionados",
  "NameBDLastAdd": "O nome do banco de dados já foi adicionado",
  "addProtocolMod": "Adicionar protocolos modbus",
  "addHeader": "Adicionar cabeçalhos",
  "dataAcquisition": "Aquisição de Dados",
  "frecuencyUnit": "Unidade de frequência",
  "frecuencyValue": "Valor de frequência",
  "configHeader": "Configuração do cabeçalho",
  "addConfigHeader": "Adicionar configuração do cabeçalho",
  "nameHeader": "Nome do cabeçalho",
  "nameBD": "Nome BD",
  "action": "Ação",
  "directionIp": "Endereço IP",
  "port": "Porta",
  "protocolModbus": "Protocolo modbus",
  "addProtocol": "Adicionar Protocolo",
  "register": "Registro",
  "dataLength": "Comprimento dos dados",
  "tData": "T. Dados",
  "typeData": "Tipo de dados",
  "unit": "Unidade",
  "concentrator": "Concentrador",
  "sim": "Sim",
  "simNumber": "Número de sim",
  "subType": "Subtipo",
  "nameOfHeader": "Nome do cabeçalho",
  "idConcentrator": "ID do concentrador",
  "adquitionData": "Aquisição dos dados",
  "infoOfMeter": "Informações do medidor",
  "infoAvanzedMeter": "Informações avançadas do medidor",
  "configModBus": "Configuração ModBUS",
  "notInfoOperation": "A operação não possui informações.",
  "operation": "Operação",
  "typeEmail": "Tipo de E-mail",
  "detailAudit": "Detalhe da Auditoria",
  "fileNoXlsx": "O arquivo não é um xlsx",
  "fileNoZip": "O arquivo não é um zip",
  "uploader": "Uploader",
  "selectEntitie": "Você deve selecionar uma entidade",
  "selectAction": "Você deve selecionar uma ação",
  "downloadExample": "Baixar Exemplo",
  "selectionFile": "Você deve selecionar um arquivo",
  "selectFile": "Selecionar arquivo",
  "selectOneField": "Você deve selecionar pelo menos um campo",
  "selectMergeUnique": "Selecione combinação única...",
  "stringZero": "Considerar vazios como String vazia",
  "selectionMethods": "Selecione método",
  "firstProcess": "Iniciar Processo",
  "History": "Histórico",
  "entiti": "Entidade",
  "process": "Processo",
  "mergeUnique": "Combinação Única",
  "treatmentUpdating": "Tratamento na atualização",
  "aprovved": "Aprovado",
  "isSecureQuestion": "Você tem certeza?",
  "generateReport": "Gerar relatório",
  "dateOfReport": "Data do relatório",
  "selectionYear": "Você deve selecionar o ano",
  "criteryDownload": "Critério de download",
  "selectionCritery": "Você deve selecionar um critério",
  "criteryEDownload": "Critério de download",
  "allGroups": "Todos os grupos",
  "allCompanies": "Todas as empresas",
  "selectionAllGroup": "Você deve selecionar pelo menos um grupo",
  "AllGroupsWereSelected": "Todos os grupos foram selecionados",
  "AllCompaniesWereSelected": "Todas as empresas foram selecionadas",
  "reportCreateExit": "Relatório criado com sucesso",
  "selectionMonth": "Você deve selecionar o mês",
  "selectionDates": "Selecione uma data",
  "allPoint": "Todos os pontos",
  "selectionOnePoint": "Você deve selecionar pelo menos um ponto",
  "selectioAllPoint": "Todos os pontos foram selecionados",
  "selectionMeter": "Você deve selecionar pelo menos um medidor",
  "AllMeterSelection": "Todos os medidores foram selecionados",
  "inserDateValidate": "Você deve inserir uma data válida",
  "typesServices": "Tipos de serviço",
  "selectionOneService": "Você deve selecionar pelo menos um serviço",
  "selectionAllService": "Todos os serviços foram selecionados",
  "selectionOneDigitation": "Você deve selecionar pelo menos um digitador",
  "selectionAllType": "Todos os tipos foram selecionados",
  "creates": "Criado",
  "periode": "Período",
  "payment": "Pagamento",
  "dateUpload": "Data de upload",
  "dateAssing": "Data atribuída",
  "dateExpedition": "Data de expedição",
  "selectionOneRegional": "Você deve selecionar pelo menos uma regional",
  "options": "Opções",
  "consumedMonth": "Consumo mensal",
  "separateCleanLight": "Separação de limpeza",
  "viewsSubProject": "Mostrar Subprojeto",
  "toChooseAllMarketer": "Todas as comercializadoras foram escolhidas",
  "firstDateReport": "Primeira data do relatório",
  "secondDateReport": "Segunda data do relatório",
  "yearAndMonth": "Ano e mês",
  "allServices": "Todos os serviços",
  "ConvertValuePositive": "Converter para Valores Positivos",
  "logSistem": "Log do Sistema",
  "newVersion": "Nova versão",
  "allAccount": "Todas as contas",
  "selectionOneAccount": "Você deve selecionar pelo menos uma conta",
  "allsAccount": "Todas as contas foram selecionadas",
  "reportCreaoteOkAlert": "Relatório criado com sucesso",
  "selectionOneGroup": "Você deve selecionar um grupo",
  "monthReport": "Mês do relatório",
  "load": "Carga",
  "preview": "Pré-visualizar",
  "engrave": "Gravar",
  "budgetAvailible": "Não há orçamentos disponíveis",
  "check": "Verificar",
  "payBill": "Pagar fatura",
  "reviewBill": "Revisar fatura",
  "changePayment": "Alterar Pagamento",
  "spradPaymentAccount": "Propagar pagamento de conta",
  "viewInfo": "Ver Informação",
  "createNoveltys": "Criar Novidades",
  "staticsBill": "Estatísticas de Faturas",
  "selectionBudgetAddBill": "Selecione um orçamento para adicionar à fatura",
  "config": "Configuração",
  "accountRestore": "Conta restaurada",
  "accountGirl": "Conta filha",
  "outOfBudget": "Fora do orçamento",
  "NotPlataformRegister": "Não registrado na plataforma",
  "expiration": "Vencimento",
  "suspend": "Suspensão",
  "due": "Vencimento",
  "notBNudget": "Sem orçamento",
  "dataPayment": "Dados de pagamento",
  "delet": "Excluir",
  "TheFileDelete": "O arquivo foi excluído",
  "recordUploadFile": "Lembre-se de que será carregada uma nova fatura, indicando que mais de uma fatura chegou neste mês",
  "continue": "Continuar",
  "newUploabill": "Enviar uma nova fatura",
  "newUpdateBill": "Alterar Fatura",
  "alertRejectBill": "Lembre-se de que as faturas rejeitadas serão excluídas completamente",
  "rejectBill": "Rejeitar fatura",
  "deleteBill": "Excluir fatura",
  "deleteVoucher": "Excluir comprovante",
  "dateBillPrompt": "Data de pagamento oportuno",
  "NotObservations": "Sem observações",
  "infoBillOf": "informações da fatura de",
  "obsOfTheBill": "Observações da fatura",
  "noveltyOfTheBill": "Novidades da fatura",
  "followBill": "Acompanhar a fatura",
  "conceptTheBill": "Conceitos da fatura",
  "moneyBags": "Bolsas de dinheiro",
  "phones": "Telefonia",
  "mobilePhone": "Telefone Móvel",
  "day": "Dia",
  "selection": "Selecionar",
  "obs": "Observação",
  "selections": "Selecionados",
  "recharge": "Recarregar",
  "aPublic": "A. Público",
  "accountInactive": "Conta inativa",
  "pointInactive": "Ponto inativo",
  "accountAndPointInactive": "Conta e ponto inativos",
  "viewAdmiPoint": "Ver Adm. Pontos",
  "notInvoiced": "Não faturado",
  "configUpdateNotification": "Configuração de notificação atualizada",
  "configTextNotification": "Configuração de notificações de",
  "notificarEi": "Notificar a",
  "whenUploadBill": "quando uma fatura for carregada",
  "whenUploadVoucher": "quando um comprovante for carregado",
  "whenRejectBill": "quando uma fatura for rejeitada",
  "whenSendPetitionBillMarketer": "solicitação de faturas por e-mail",
  "notificationTypeUser": "Notificar ao tipo de usuário",
  "managers": "Gerente",
  "payer": "Pagador",
  "client": "Cliente",
  "loa": "Carga",
  "notTypeUser": "Sem tipo de usuário",
  "petitionBillAcoount": "Petição de fatura à conta",
  "companyUpdateOk": "Empresa atualizada com sucesso",
  "companyNewOk": "Empresa criada com sucesso",
  "pleaseInserrNameValid": "Por favor, insira um nome válido!",
  "pleaseInsertTypeValid": "Por favor, insira um tipo válido!",
  "updateStateAccount": "Alterar estado da conta",
  "auditOfPoint": "Auditorias de pontos",
  "updateStatePointAsk": "Tem certeza de que deseja alterar o estado deste ponto?",
  "stateOfPointUpdate": "Estado do ponto alterado",
  "verificedStanBY": "Verificação pendente",
  "correo": "Correio",
  "stateOfPoint": "Estado do ponto",
  "exportListUpdate": "Exportar listas de alterações",
  "permitNotificationPayment": "Permitir notificações de pagamento",
  "authorizedPaymet": "Pagamento autorizado",
  "lvlSocio": "Nível socioeconômico",
  "lvlVoltage": "Nível de voltagem",
  "userRealizedUpdate": "Usuário que realizou a alteração",
  "notDateOfOperation": "Sem data de operação",
  "noveltyAccount": "Novidades da conta",
  "insertFilePlaneEnel": "Enviar arquivo padrão de Enel",
  "updateStateofAccount": "Alterar estado da conta",
  "IsSecureUpodateStateAccountAsk": "Tem certeza de que deseja alterar o estado desta conta?",
  "stateAcconuntModif": "Estado da conta alterado",
  "auditAccountNumber": "Auditorias do número da conta",
  "ofPoint": "do ponto",
  "pServicio": "P. Serviço",
  "dateTI": "Datas T - I",
  "accountFathers": "C. pai",
  "idNeuAccount": "ID da nova conta",
  "newNameOfAccount": "Novo nome da conta",
  "newMarketerService": "Novo provedor de serviço",
  "updateRealizedFor": "alteração realizada por",
  "lastMarketer": "provedor anterior",
  "updateMarketerForUser": "O usuário alterou o provedor de serviço",
  "he": "ele",
  "eiTheMarketer": "ao provedor de serviço",
  "withAccountNumber": "com número da conta",
  "notUpdateMarketer": "Não houve alteração no provedor de serviço",
  "dateTimelyPayment": "Data de pagamento oportuno",
  "dateInitialOfBilling": "Data inicial de faturamento",
  "paymentAuthorized": "Pagamento autorizado",
  "authorizedForPaiment": "Autorizado para pagamento",
  "dateServices": "Datas de serviços",
  "NumberCuentaEnergy": "# Conta de energia",
  "numberAccountOfStreetLight": "# Conta de iluminação pública",
  "numberAccountWater": "# Conta de água",
  "numberAccountOfSewerage": "# Conta de esgoto",
  "numberAccountCleanlines": "# Conta de limpeza",
  "numberAccountGas": "# Conta de gás",
  "voltageLvl": "Nível de tensão",
  "infoOdfTheAccount": "Informações da conta",
  "editNovelty": "Editar novidades",
  "theGroupDeleteOk": "O grupo foi excluído com sucesso",
  "codOfgroup": "Código do grupo",
  "notName": "Sem nome",
  "dateUploadOfBill": "Data de envio da fatura",
  "dateOfPaymentBill": "Data de pagamento",
  "back": "Voltar",
  "companys": "Empresas",
  "nameOftheTransaction": "Nome da transação",
  "balanceFirst": "Saldo inicial",
  "balanceEnd": "Saldo final",
  "clickFileRates": "Clique ou arraste o arquivo da transação",
  "manualReconciliationss": "Conciliação manual",
  "reconciliation": "Conciliadas",
  "notReconciliation": "Não conciliada",
  "notDate": "Sem data",
  "notReconciliatioons": "Não conciliada",
  "operationConsolidedOk": "Operação consolidada com sucesso",
  "historyBalances": "Histórico de saldos",
  "uploadProofs": "Enviar comprovantes",
  "lastClose": "Último fechamento",
  "dateNotOfFirst": "Data de início",
  "createResouceSource": "Criar origem de recurso",
  "sourceResourceNotNumber": "Origem de recurso sem número",
  "states": "Estados",
  "sorceResourceOk": "Origem do recurso corretamente",
  "budgetCalculation": "Cálculo de orçamento",
  "gestionBudget": "Gerenciar orçamentos",
  "isSecurteDeleteBudgetAlertAsk": "Tem certeza de que deseja excluir este orçamento?",
  "budgetDeleteOk": "Orçamento excluído com sucesso",
  "isSecureAskAprovedBudget": "Tem certeza de que deseja aprovar este orçamento?",
  "theBudgetisAproved": "O orçamento foi aprovado",
  "isSecureDuplicateBudgetAsk": "Tem certeza de que deseja duplicar este orçamento",
  "budgetDuplicateOk": "Orçamento duplicado com sucesso",
  "selectAQuoteToSync": "Selecione um orçamento para sincronizar",
  "budgetCreateOk": "Orçamento criado com sucesso",
  "note": "Nota",
  "textBudgetOne": " As contas que serão usadas para o cálculo do orçamento são aquelas autorizadas para pagamento. Se as faturas ainda não chegaram e não foram digitadas, a data de pagamento aproximada e o valor aproximado de pagamento serão usados.",
  "generateBuget": "Gerar Orçamento",
  "dateCreate": "Data de criação",
  "view": "Visualizar",
  "aprovedQuote": "Aprovar orçamento",
  "downloadDetail": "Baixar Detalhe",
  "duplicateQuote": "Duplicar orçamento",
  "syncBudget": "Sincronizar orçamento",
  "budgetToSyncOk": "Orçamento sincronizado com sucesso",
  "accounrAddOk": "Conta adicionada com sucesso",
  "isPaymentregisterBudget": "Este pagamento já está registrado no orçamento",
  "budgetUpdateOk": "Orçamento modificado com sucesso",
  "isSecureUpdateQuotes": "Tem certeza de que deseja alterar o estado do orçamento",
  "budgetSaveOk": "Orçamento salvo com sucesso",
  "addAccountBudget": "Adicionar conta ao orçamento",
  "filterBills": "Filtrar Faturas",
  "lastBills": "Últ. Fatura",
  "codeOffice": "Cod. Escritório",
  "createRole": "Criar função",
  "editRole": "Editar função",
  "notsverificied": "Não verificado",
  "notSendNotificationsAlert": "O envio de notificações foi desativado",
  "sendNotificationsAlert": "O envio de notificações foi ativado",
  "petitionBillDesactivate": "Petição de Fatura Desativada",
  "petitionBillActivate": "Petição de Fatura Ativada",
  "codMovii": "Código movii",
  "digitVerification": "Dígito de verificação",
  "insertNumberDigitation": "Você deve digitar o número de verificação",
  "modo": "Modo",
  "notificationDelete": "Notificação excluída com sucesso",
  "deleteNotificationAlerQuestion": "Tem certeza de que deseja excluir esta notificação?",
  "createNotification": "Criar notificação",
  "editNotification": "Editar notificação",
  "updateNotificationAlert": "Notificação modificada com sucesso",
  "notificationCreateOk": "Notificação criada com sucesso",
  "petitionBill": "Pedido de fatura para",
  "createConcepts": "Criar conceito",
  "subService": "Sub-serviço",
  "textOneCreateConcetp": "Desativar subprojetos se não se aplicarem ao tipo de serviço",
  "equevalenceExist": "Esta equivalência já existe",
  "equivalence": "Equivalência",
  "atMenosEquivalence": "Pelo menos uma equivalência",
  "fieldObligatoeyValue": "Este campo é obrigatório, por favor insira um valor",
  "nameOfEquevalence": "Nome da equivalência",
  "addNewqEquivalence": "Adicionar uma nova equivalência",
  "saveEquievalence": "Salvar equivalência",
  "saveUpdate": "Salvar alterações",
  "confir": "confirmar",
  "configs": "Configurações",
  "audit": "Auditoria",
  "emailss": "Correio",
  "payment_aut": "Pagamento autorizado",
  "psERVICE": "P. de Serviço",
  "notCity": "Sem cidade",
  "notdeparment": "Sem departamento",
  "notPropieruty": "Sem propriedade",
  "notLvlVariedade": "Sem nível de variação",
  "rateOptional": "Tarifa opcional",
  "basic": "básico",
  "valueFije": "Valor fixo",
  "saldeMinime": "Salários Mínimos",
  "ratesUpdateOk": "Tarifa modificada com sucesso",
  "ratesOkCreate": "Tarifa criada com sucesso",
  "generates": "Geração",
  "tranmition": "Transmissão",
  "distribution": "Distribuição",
  "comercialization": "Comercialização",
  "retriction": "Restrições",
  "lossesAndReduction": "Perdas e redução",
  "valueEnergy": "Valor da energia",
  "optionTarida": "Opção tarifária",
  "valueOption": "Valor da opção",
  "textRatesOne": "Já existe um arquivo carregado, se um novo for carregado, o anterior será excluído",
  "clickFileRatess": "Clique ou arraste o arquivo da tarifa",
  "createSetting": "Criar configuração",
  "editSetting": "Editar configuração",
  "isSecureDeleteSetting": "Tem certeza de que deseja excluir esta configuração?",
  "settingDeleteOk": "Configuração excluída com sucesso",
  "valueColumns": "Valores",
  "ValueOf": "Valores de",
  "viewValues": "Ver valores",
  "table": "Tabelas",
  "tableSyncro": "Tabela sincronizada",
  "syncro": "Sincronizar",
  "viewss": "Visualizar",
  "detailOdoo": "Detalhe Odoo",
  "displayInformation": "Exibir informações",
  "ocultInfo": "Ocultar informações",
  "calculatorAmbiental": "Calculadora ambiental",
  "editPoint": "Editar ponto",
  "isSecureDeleteElement": "Tem certeza de que deseja excluir este elemento?",
  "elementDeleteOk": "Elemento excluído com sucesso",
  "iluminationActivate": "Ativação da iluminação",
  "notIluminationDeactivate": "Sem desativação da iluminação",
  "pointVisit": "Ponto visitado",
  "notPointVisit": "A visita ao ponto foi desativada",
  "aireVisit": "Ativação do ar condicionado",
  "notAireVisit": "Sem ativação do ar condicionado",
  "exportMultiPoint": "Exportar multipontos",
  "iluminations": "Iluminação",
  "aire": "Ar condicionado",
  "visit": "Visita",
  "deleteOeditOne": "Você só pode excluir ou editar um de cada vez",
  "downloadReport": "Baixar relatório",
  "typeOffice": "Tipo de escritório",
  "numbersEmployer": "Número de empregado",
  "activa": "Ativa",
  "reactiva": "Reativa",
  "light": "Iluminação",
  "acueduct": "Aqueduto",
  "numberAccounts": "Número de contas",
  "numberMeters": "Número do medidor",
  "shedules": "Horários",
  "element": "Elemento",
  "photo": "Fotos",
  "elementAddOk": "Elemento adicionado com sucesso",
  "assingGestor": "Atribuir usuários gestores",
  "userAddOk": "Usuários adicionados com sucesso",
  "userDeleteOk": "Usuários excluídos com sucesso",
  "sinAudit": "Sem auditoria",
  "auditPayment": "Auditoria de pagamento",
  "useraddgestor": "Usuários adicionados ao ponto com sucesso",
  "userDeletedGestor": "Usuários excluídos do ponto com sucesso",
  "deleteUser": "Excluir usuário",
  "assignUsers": "Atribuir usuários",
  "adimin": "Administração",
  "totallyBillAnual": "Total de faturas anual",
  "totallyBillMonth": "Total de faturas mensal",
  "billSinBill": "Fatura sem envio",
  "billMonth": "Fatura com frequência mensal",
  "billDigitid": "Faturas digitadas",
  "billFrequencyMontly": "Fatura com frequência bimensal",
  "billPedientDigit": "Faturas pendentes para digitar",
  "billPendie": "Faturas pendentes",
  "billFrequencyTri": "Fatura com frequência trimestral",
  "billPayment": "Faturas pagas",
  "billFrequencyAnual": "Fatura com frequência anual",
  "billReject": "Faturas rejeitadas",
  "formule": "Fórmula",
  "changefrecuency": "Alterar frequência",
  "updloadbillmethod": "Método de carregamento de fatura",
  "makechange": "Alteração realizada com sucesso",
  "incumbent_marketer": "Fornecedores de serviços",
  "emailverification": "Verificar e-mail",
  "removeemailnotification": "Remover verificação de e-mail",
  "executed_mode": "Modo executado",
  "marketer_user_type": "Tipo de usuário",
  "applyfilters": "Aplicar filtros",
  "supervisor": "Supervisionar visualização do pagamento",
  "companies": "Empresas",
  "pay_regionals": "Grupos",
  "types_pay": "Tipos",
  "pays_marketers": "Fornecedores",
  "asignee": "Pagador",
  "payorder": "Ordem de pagamento",
  "filter": "Filtro",
  "supervisortag": "Supervisor",
  "issue": "Data de emissão",
  "reviewModal": "Revisão de faturas",
  "review": "Revisar",
  "reviewsubtype": "Subtipo de revisão",
  "approvepayment": "Aprovar pagamento",
  "removereview": "Remover revisão",
  "restorereview": "Restaurar revisão",
  "payappropve": "Pagamento aprovado",
  "approved_payment": "Pagamento aprovado",
  "digitation_status_filter": "Status de digitação",
  "datebill": "Data de início da faturação",
  "estimate_issue_date": "Data estimada de emissão",
  "budget_apply": "Aplicar orçamento",
  "active": "Ativo",
  "revision_status": "Status de revisão",
  "services_config": "Configuração de serviços",
  "services_message": "Você deve selecionar um serviço",
  "update_settings": "Atualização de configurações",
  "uploadBill": "Carregar fatura",
  "client_upload_bill": "Conta de carregamento do cliente",
  "charger": "Carregador",
  "odoo_alert": "Gerar alertas Odoo",
  "update_bill": "Atualizar conta",
  "consumption_matrix": "Matriz de consumo",
  "fat_file": "Arquivo FAT",
  "conciliations": "Conciliações",
  "movementsandtransfers": "Movimentos e transferências",
  "created_at": "Data de criação",
  "voucher_number": "Número do voucher",
  "date_paid": "Data de pagamento",
  "user_created": "Usuário criado",
  "target": "Objetivo",
  "payment_date": "Data de pagamento",
  "date_bill": "Data da conta",
  "real_payment": "Pagamento real",
  "paid_date": "Data de pagamento",
  "regional_name": "Regional",
  "number_bill": "Número da conta",
  "payment_reference": "Referência de pagamento",
  "cash_source_name": "Fonte de dinheiro",
  "account_name": "Número da conta",
  "payment_method_marketer_name": "Método de pagamento",
  "is_consolidated_true": "Consolidado",
  "is_consolidated_false": "Não consolidado",
  "payment_tax": "Impostos",
  "consolidated": "Consolidado",
  "not_consolidated": "Não consolidado",
  "property_level": "Nível de propriedade",
  "voltaje_level": "Nível de tensão",
  "phone_number": "Número de telefone",
  "guru_groups": "Grupos do Guruve",
  "clear_selections": "Limpar seleções",
  "places": "Pontos",
  "max_three_selected": "Você pode selecionar no máximo três grupos",
  "history": "Histórico",
  "get_history_id_message": "Você deve selecionar um usuário para exibir seu histórico de dados.",
  "operation_name": "Operação",
  "cash_source": "Fonte de dinheiro",
  "see_history": "Ver histórico do usuário",
  "messages": "Mensagens",
  "see_messages": "Ver mensagens do usuário",
  "schedules": "Agendamentos",
  "message_title": "Título da mensagem",
  "message": "Mensagem",
  "buttons": "Botões",
  "guruve_buttons_add": "Adicionar botões à mensagem",
  "section_title": "Título da seção",
  "body_message": "Insira as especificações do formulário.",
  "body_message_title": "Corpo do formulário",
  "guru_message_types": "Tipos de mensagem",
  "guru_messages_status": "Status da mensagem",
  "total_sent": "Total de mensagens enviadas",
  "send_date": "Data de envio",
  "send_date_and_hour": "Data e hora de envio",
  "included_groups": "Grupos incluídos",
  "excluded_groups": "Grupos excluídos",
  "included_users": "Usuários incluídos",
  "excluded_users": "Usuários excluídos",
  "includ_all": "Incluir todos",
  "programing": "Programação",
  "targets": "Metas",
  "get_schedule_message_info": "Você deve selecionar uma mensagem para exibir sua configuração.",
  "aprove": "Aprovar",
  "reject": "Rejeitar",
  "send_test": "Enviar teste",
  "send_date_and_hour_message": "Sua mensagem foi programada para ser enviada na data e hora desejadas, se você desejar modificá-la você pode fazê-lo antes da hora marcada.",
  "change_message": "Mudar mensagem",
  "duplicated": "Duplicar",
  "click_and_drag": "Clique ou arraste o arquivo para esta área para fazer upload",
  "click_and_drag_message": "Suporte para upload único. É estritamente proibido fazer upload de dados da empresa ou outros arquivos não autorizados.",
  "direct_debit_payment": "Débito Direto",
  "changePriority": "Alterar Prioridade",
  "changeNormalToPriority": "Alterar para Prioridade Normal",
  "changeHighToPriority": "Alterar para Prioridade Alta",
  "priority": "Prioritário",
  "ready_for_approval": "Pronto para aprovação",
  "scheduled": "Aprovado e agendado",
  "sent": "Enviado",
  "rejected": "Rejeitado",
  "cancelled": "Cancelado",
  "sending_message": "Enviando mensagem",
  "delivered": "Entregue",
  "received": "Recebido",
  "seen": "Visto",
  "text": "Texto",
  "list": "Lista",
  "media": "Mídia",
  "button": "Botão",
  "button_reply": "Resposta de botão",
  "list_reply": "Resposta de lista",
  "sticker": "Sticker",
  "incoming": "Entrada",
  "no_message": "Nenhuma mensagem",
  "sent_at": "Enviado",
  "delivered_at": "Entregue",
  "read_at": "Lido",
  "see_more": "Ver mais",
  "information": "Informação",
  "add_form_field": "Adicionar campo de formulário",
  "add_list_item": "Adicionar item de lista",
  "add_button_field": "Adicionar botão",
  "edit_schecule_message": "Editar mensagem programada",
  "saving": "Salvando...",
  "payment_method": "Forma de pagamento",
  "edit_message_name": "Editar nome da mensagem",
  "edit_schedule": "Editar contenidos",
  "clear_last_selections": "Limpar últimas seleções",
  "total_user_bill_downloads": "Número total de logs concluídos",
  "estimated_upload_date": "Data de upload estimada",
  "upload_month": "Mês de upload",
  "scheduled_at": "Programado para",
  "mode": "Modo",
  "review_sub_type": "Subtipo de revisão",
  "assigned": "Atribuído",
  "total_pending": "Total pendente",
  "total_pending_priority": "Total pendente prioritário",
  "assignee_charger": "Atribuir carregador",
  "assignee_payer": "Atribuir pagador",
  "digitation_status": "Status da digitação",
  "user_bill_download": "Baixar conta do usuário",
  "remove_children_account": "Remover do padrão",
  "action_sucesfull": "Ação realizada com sucesso",
  "remove_account_of_bill_parent": "Remover conta da conta pai",
  "remove_pay_from_bill": "¿Você deseja remover esta fatura do pagamento?",
  "payment_method_suggest": "Sugerir forma de pagamento",
  "upload_parent_file_bill": "Carregar arquivo de fatura pai",
  "clickUploadBill": "Clique para carregar a fatura",
  "simple_mode": "Modo simples",
  "report_mode": "Modo de relatório",
  "childs": "Filhas",
  "parent": "Pai",
  "loader": "Carregador",
  "add_selected": "Adicionar selecionado",
  "automatic_debit": "Débito automático",
  "energy_border": "Fronteira",
  "account_cession": "Cessão de conta",
  "account_cession_susecfull": "Conta cedida com sucesso",
  "accounts": "Contas",
  "stratum": "Estrato",
  "position": "Posição",
  "id_card": "Cédula",
  "bill_upload": "Carregar fatura",
  "total files": "Total de ficheiros",
  "difference": "diferença",
  "reconciled": "reconciliado",
  "notCancelled": "Não cancelado",
  "number_of_agreement": "Número de acordo",
  "date_ipp_ipc_base": "Data IPP/IPC base",
  "contributing_rate": "Taxa de contribuição",
  "collection_method": "Método de coleta",
  "help": "Ajuda",
  "rates_hepl_one": "Variáveis armazenadas com um símbolo Hash/Cerquilha #",
  "rates_hepl_two": "Deixe um espaço antes de declarar uma variável",
  "rates_hepl_three": "Separe cada dado com vírgula, por exemplo: (#SMMLV + 30), (#SMMLV+30)*15",
  "rates_hepl_four": "Ao usar um operador ternário, lembre-se de usar '?' e depois ':' Exemplo:",
  "rates_hepl_five": "É usado para um '&&' e o que é usado para um '||'",
  "rates_hepl_six": "No caso de um operador ternário aninhado não ter a última validação, por favor, defina o valor como VERDADEIRO.",
  "public_lighting_fee": "Taxa de iluminação pública",
  "timely_payment_date_budget": "Data estimada de pagamento do orçamento",
  "daily_number": "Número diário",
  "filter_date": "Filtrar por data",
  "ilumination_office": "Iluminação oficina",
  "aires_office": "Aires oficina",
  "ilumination_atm_office": "Iluminação ATM oficina",
  "aires_atm_office": "Aires ATM oficina",
  "aire_data_center": "Aire data center",
  "hydric": "Hídrico",
  "init_date": "Data de início",
  "end_date": "Data final",
  "add_investments": "Adicionar investimentos",
  "base_lines": "Linhas de base",
  "remove_cession": "Remover cessão",
  "bot_upload_bill": "Bot de subida de factura",
  "implementation_upload_bill": "Implementação de subida de factura",
  "timely_payment_date": "Data de pagamento pontual",
  "consumption": "Consumo",
  "image_bill": "Fatura de imagem",
  "create_account": "Criar conta",
  "upload_bill": "Carregar fatura",
  "view_upload_bill_info": "Ver informações da fatura carregada",
  "account_relationship": "Relação de conta",
  "budgetFixed": "Orçamento fixo",
  "unreconciledTransactions": "Transações não reconciliadas",
  "user_information": "Informação do usuário",
  "add_guru_mode_message": "Para acessar este módulo, você deve adicionar um modo de guruve ao seu perfil de usuário através do seguinte link:",
  "message_template": "Modelo de mensagem",
  "template_message_intro": "Bem-vindo! Neste espaço, você terá a oportunidade de escolher o modelo perfeito para sua mensagem de boas-vindas no WhatsApp.",
  "header": "Cabeçalho",
  "messages_lobby": "Bem-vindo ao formulário de criação de mensagens da Guruve! Aqui você terá a oportunidade de configurar e personalizar a sua mensagem de acordo com o seu gosto.",
  "messages_lobby_1": "Escolha e personalize o modelo de boas-vindas para a sua mensagem.",
  "messages_lobby_2": "Crie mensagens de acordo com suas preferências.",
  "messages_lobby_3": "Interaja com os usuários do bot criando condições de resposta para as mensagens enviadas.",
  "conditions_title": "Condições",
  "conditions_message_intro": "Aqui você pode gerar condições para as mensagens que você envia através do Guruve, as quais podem ser usadas para validar mensagens, oferecer opções para o usuário selecionar uma alternativa ou outra, e muito mais.",
  "conditions_message_example": "Estes são exemplos de uso de uma condição: $id_mensagem.value === 'Sim' para consultar uma idade, por exemplo, ou validar se o consumo de faturas é alto: $consumo_fatura.value > '100000' ou $age.value > '18', entre muitas outras consultas e validações.",
  "message_intro": "Personalize suas mensagens ao seu gosto para serem enviadas através do Guruve. Você pode enviar vídeos, imagens e adesivos.",
  "add_on_tree": "Adicionar à árvore de mensagens",
  "export_users_bill_upload": "Exportar usuários com faturas carregadas",
  "dates_information": "Informações de datas",
  "origin_type": "Tipo de origem",
  "start_date": "Data de início",
  "destination_name": "Nome de destino",
  "no_interactions": "Você deve selecionar uma interação para poder ver as informações das mensagens enviadas.",
  "interactions": "Interações",
  "export_sent_messages": "Exportar mensagens enviadas",
  "points": "Pontos",
  "carbon_print": "Pegada de carbono",
  "meters_variation": "Variação de bitola",
  "energy": "Energia",
  "see_carbon_footprint": "Ver pegada de carbono",
  "alerts": "Alertas",
  "total_active_energy": "Energia ativa total",
  "total_reactive_energy": "Energia reativa total",
  "average_consumption": "Consumo médio",
  "actual_savings": "Economia real",
  "last_year": "Último ano",
  "company_information": "Informações da empresa",
  "monthly_comparison": "Comparação mensal",
  "values": "Valores",
  "energy_consumption": "Consumo de energia",
  "reactive_power_consumption": "Consumo de energia reativa",
  "base_consumption": "Consumo básico",
  "hide_accounts_without_data": "Ocultar contas sem dados",
  "billUpload": "Upload de fatura",
  "massive_logbook": "Livro de registro em massa",
  "provison_synlab_file": "Arquivo de provisão Synlab",
  "filter_base_consumption": "Consumo de base do filtro",
  "relationship": "Relação",
  "jump_message_intro": "Você pode usar os saltos para saltar para uma mensagem específica usando os IDs das mensagens.",
  "jump": "Saltar",
  "projected": "Projetado",
  "vertebracanmakethepay": "Vertebra pode fazer o pagamento",
  "old_account_change_marketer": "O usuário [user_make_change] realizou uma mudança de comercializadora do fornecedor [old_provider] para o fornecedor [new_provider] da conta [old_account] para a conta [new_account] com registro de XM [datexm] na data [date]. A mudança está sob a modalidade [modality] e a modalidade de venda é [sales_modality].",
  "new_account_change_marketer": "O usuário [user_make_change] gerou a mudança de comercializadora da conta [old_account] para a conta [new_account] com registro de Xm [datexm].  a data de modificação é [date].",
  "document": "Documento",
  "removeChangeMarketer": "Remover mudança de comercializadora",
  "not_registered": "Não registrado",
  "respel": "Respel",
  "internet": "Internet",
  "application": "Aplicação",
  "message_name": "Nome da mensagem",
  "multiple_company_schedule": "Programação de várias empresas",
  "unbilled_month": "Mês não faturado",
  "invoice_account": "Conta de faturamento",
  "withoutBiReport": "Você não possui relatórios atribuídos a esta empresa",
  "see_childrens": "Ver filhos",
  "see_provider": "Ver provedor de serviços",
  "see_company": "Ver empresa",
  "parent_account": "Conta pai",
  "Scheduled": "Programado",
  "schedule_messages": "Mensagens programadas",
  "extra_info": "Informações extras",
  "message_extra_info": "Informações extras da mensagem",
  "error_validation": "Erro de validação",
  "validation": "Validação",
  "startwith": "Começa com",
  "endwith": "Termina com",
  "subtype": "Subtipo",
  "optional_info": "Informação opcional",
  "message_optional_info": "Informação opcional da mensagem",
  "exeptionCommand_type": "Tipo de comando de exceção",
  "exeptionCommandText": "Texto de comando de exceção",
  "sendNext": "Enviar próximo",
  "vertebra_can_pay": "Vertebra pode pagar",
  "type_place": "Tipo de lugar",
  "changeMarketer": "Mudança de comercializadora",
  "has_account_cession": "Cessão de conta",
  "novelty_account": "Novidade da conta",
  "office_code": "Código do escritório",
  "estimatePaymentDate": "Data estimada de pagamento",
  "new_marketer": "Novo comercializador",
  "withoutData": "Sem dados",
  "switchVerification": "Mudar verificação",
  "withoutInformation": "Sem informação",
  "street_lighting": "Iluminação pública",
  "cleanliness": "Limpeza",
  "insertbillpetition": "Inserir o número da conta",
  "upload_enel_file": "Carregar arquivo Enel",
  "assigned_payer": "Pagador atribuído",
  "estimate_payment_date": "Data estimada de pagamento",
  "initial_payment_date": "Data de pagamento inicial",
  "final_payment_date": "Data de pagamento final",
  "billing_to": "Faturamento",
  "iva_value": "Valor do IVA",
  "telemetry_value": "Valor da telemetria",
  "data_plan_vallue": "Valor do plano de dados",
  "dateChange": "Data de mudança",
  "sell_modality": "Modalidade de venda",
  "spot_date": "Data do pagamento à vista",
  "financing_date": "Data de financiamento",
  "number_of_payments": "Número de pagamentos",
  "final_pay_date": "Data de pagamento final",
  "remove_change_marketer": "Remover mudança de comercializadora",
  "export_interactions": "Exportar interações",
  "export_unregistered_user_interactions": "Exportar interações de usuários não registrados",
  "discounted_call": "Discounted call",
  "unregistered_users": "Usuários não registrados",
  "brand": "Marca",
  "typeEquipment": "Tipo de equipamento",
  "lightingType": "Tipo de iluminación",
  "inventory": "Inventário",
  "account_changes": "Mudanças de conta",
  "mode_of_sale": "Modo de venda",
  "offer_number": "Número da oferta",
  "modality": "Modalidade",
  "investments": "Investimentos",
  "change_date_to_regulatory_bodies": "Data de alteração aos órgãos reguladores",
  "valve": "Válvula",
  "meter_characterization": "Caracterização do medidor",
  "include_points_without_investments": "Incluir pontos sem investimentos",
  "include_points_without_inventory": "Incluir pontos sem inventário",
  "with": "Com",
  "without": "Sem",
  "error": "Erro",
  "message_information": "Informação da mensagem",
  "week": "Semana",
  "budget_value": "Valor do orçamento",
  "budget_value_not_equal_total": "O valor do orçamento não é igual ao total",
  "budget_account_date": "Data orçada",
  "payment_immediate": "Pagamento imediato",
  "diferent_values": "Valores diferentes",
  "export_account": "Exportar conta",
  "export_bills_history": "Exportar histórico de faturas",
  "bills_without_export": "Faturas sem exportação",
  "current": "Atual",
  "previous": "Anterior",
  "payment_today": "Pagamento hoje",
  "expires_today": "Expiran hoje",
  "active_since": "Ativo desde",
  "compare_budgets": "Comparar orçamentos",
  "indexer": "Indexador",
  "base_date": "Data base",
  "adequacy_value": "Valor de adequação",
  "equipment_value": "Valor dos equipamentos",
  "labor_cost_value": "Valor da mão de obra",
  "exit_penalty_percentage": "Percentagem de penalização de saída",
  "year": "Ano",
  "weeks_configuration_json": "JSON de configuração de semanas",
  "configure_service_percentages": "Configurar as porcentagens dos serviços",
  "service_percentages_for_budget_calculation": "Percentagens de serviços para cálculo do orçamento",
  "market": "Mercado",
  "payer_mode": "Modo de pagamento",
  "reviewer_modo": "Modo de revisão",
  "audit_mode": "Modo de auditoria",
  "bill_finder_mode": "Modo de busca de fatura",
  "modes": "Modos",
  "recalculate_weeks": "Recalcular semanas",
  "last_bot_type": "Último tipo de bot",
  "dNoHave": "Não tem",
  "queueBudgetManagement": "Gestão de orçamentos",
  "manage": "Gerenciar",
  "icon": "Ícone",
  "remove_from_budget": "Remover do orçamento",
  "out_budget": "Fora do orçamento",
  "in_budget": "Dentro de presupuesto",
  "roolback": "Roolback",
  "change_budget": "Alterar orçamento",
  "not_waiting": "Não esperando",
  "bill_arrive": "Fatura chega",
  "bill_not_arrive": "Fatura não chega",
  "arrives": "Chega",
  "waiting": "Esperando",
  "is_external_pay": "É um pagamento externo",
  "assing_massive_groups": "Atribuir grupos em massa",
  "deactivate_users": "Desativar usuários",
  "active_users": "Usuários ativos",
  "remenber": "Lembre-se",
  "assing_many_groups_warning": "Os grupos serão atribuídos a usuários que atendam aos critérios do intervalo de filtros.",
  "invoice_search": "Busca de faturas",
  "last_log": "Último registro",
  "configure_uploader_permissions": "Configurar permissões do uploader",
  "expires_at": "Expira em",
  "pin": "Pin",
  "link": "Link",
  "section": "Seção",
  "extra": "Extra",
  "old_provider": "Fornecedor antigo",
  "new_provider": "Novo provedor",
  "old_account": "Conta antiga",
  "new_account": "Nova conta",
  "registre_date": "Data de registro",
  "sales_modality": "Modalidade de venda",
  "change_marketer": "Mudança de comercializadora",
  "user_make_change": "Usuário que fez a mudança",
  "market_type": "Tipo de mercado",
  "assigned_real_date": "Data real atribuída",
  "no_reclassification": "Não usar reclassificação",
  "guru_type": "Tipo de guru",
  "define_template": "Definir modelo",
  "guruve_mode": "Modo Guruve",
  "template": "Modelo",
  "optional": "Opcional",
  "header_description": "Escolha o tipo de conteúdo multimídia que você usará para o cabeçalho da sua mensagem. (só pode ser escolhido um)",
  "header_type": "Tipo de cabeçalho",
  "none": "Nenhum",
  "image": "Imagem",
  "video": "Vídeo",
  "body": "Corpo da mensagem",
  "body_description": "Para personalizar o corpo da mensagem, insira o texto que deseja exibir. Você pode usar variáveis para personalizar ainda mais a mensagem. As variáveis são inseridas da seguinte maneira: {{número da variável}}. Exemplo: {{1}}. Certifique-se de atribuir números de variáveis em ordem crescente para que a mensagem seja exibida corretamente.",
  "characters": "Caracteres",
  "add_variable": "Adicionar variável. Além disso, você pode pressionar a tecla # para exibir um menu com várias opções.",
  "actions": "Ações",
  "actions_description": "Defina o tipo de ação que você deseja mostrar quando o usuário receber a mensagem.",
  "comment_cannot_contain_quotes": "O comentário não pode conter aspas simples ou duplas",
  "add_log": "Adicionar registro",
  "created_by": "Criado por",
  "log_today": "Registro de hoje",
  "ipp_ipc_date_configuration": "Configuração de data IPP/IPC",
  "telephony": "Telefonia",
  "television": "Televisão",
  "con_ocr": "Com OCR",
  "reason_user_charge": "Motivo de carga do usuário",
  "novelty_prevents_invoice_search": "Novidade impede busca de fatura",
  "inconsistent_charger": "Cargador incongruente",
  "manageCredentials": "Gerenciar credenciais",
  "public_lighting_short": "I. pública",
  "bot_statistics": "Estatísticas de bots",
  "bots_executed": "Bots executados",
  "exitosos": "Bem-sucedido",
  "error_types": "Tipos de erros",
  "without_invoice": "Sem fatura",
  "error_count": "Número de erros",
  "error_type": "Tipo de erro",
  "emails": "E-mails",
  "generate_official_letter": "Gerar ofício",
  "add_accounts": "Adicionar contas",
  "remove_accounts": "Remover contas",
  "at_claiming": "Reclamando",
  "remove_claim": "Remover reclamação",
  "guilty": "Culpado",
  "page_error": "Erro na página",
  "in_process": "Em processo",
  "total_attempts": "Total de tentativas",
  "view_all_details": "Ver todos os detalhes",
  "completeds": "Completados",
  "consumption_calculation": "Cálculo de consumos",
  "created_provider_ticket": "Criou ticket para o prestador",
  "file_number": "Número do protocolo",
  "created_from": "Criado a partir de",
  "open_invoice_history": "Abrir histórico de faturas",
  "invoice_history": "Histórico de faturas",
  "issue_date": "Data de emissão",
  "suspension_date": "Data de suspensão",
  "no_invoice_selected": "Nenhuma fatura selecionada",
  "created_by_bulk_logs": "Criado por logs em massa",
  "updateNoveltyAccount": "Editar problema de conta",
  "unit_cost": "Costo unitario",
  "tariff_recovery_cost": "Custo de recuperação tarifária",
  "view_bills": "Ver faturas",
  "uploader_error": "Arquivo com erros",
  "uploader_error_subtitle": "Por favor, verifique e modifique as informações do arquivo antes de fazer o upload novamente",
  "uploader_error_description": "O conteúdo que você enviou tem os seguintes erros",
  "uploader_redirect_dashboard": "Redirecionar para o painel",
  "status_report": "Relatório de status",
  "upload_massive_file": "Carregar arquivo em massa",
  "is_multiple": "É múltiplo",
  "provider_inactive_since": "Este prestador está inativo desde",
  "selected_point": "Ponto selecionado",
  "not_selected": "Não selecionado",
  "zip_conditions": "Para carregar os dados corretamente, certifique-se de que: \n* Os arquivos no ZIP correspondem ao formato do modelo. \n* Os arquivos PDF têm o formato código_do_país-número_de_telefone.formato. \nexemplo: 573XXXXXXXXX.pdf",
  "donwloadZip": "Baixar ZIP",
  "total_rows": "Total de linhas",
  "total_ok": "Total OK",
  "total_error": "Total de erros",
  "last_update": "Última atualização",
  "job_id": "ID do trabalho",
  "entity": "Entidade",
  "proccess_and_ignore_errors": "Processar e ignorar erros",
  "this_process_can_take_a_while": "Este processo pode levar um tempo",
  "download_report": "Baixar relatório",
  "display_errors": "Exibir erros",
  "payment_information": "Informação de pagamento",
  "searching_information": "Buscando informação",
  "eguru": "Eguru",
  "visitado": "Visitado",
  "edit_investments": "Editar investimentos",
  "additional_days": "Dias adicionais",
  "add_issue": "Adicionar novidade",
  "account_issue": "Problema de conta",
  "invoice_issue": "Problema de fatura",
  "internal_use_issue_vertebra": "Problema de uso interno Vertebra",
  "budget_issue": "Problema de orçamento",
  "closed_account_issue": "Problema de conta fechada",
  "change_bill": "Mudança de faturas",
  "invoice_value_affecting_account_issue": "Problema de conta que afeta o valor da fatura",
  "updated_at": "Atualizado em",
  "total_cargada": "Total carregadas",
  "total_digitadas": "Total digitadas",
  "total_no_facturadas": "Total não faturadas",
  "increase_alerts": "Alertas de aumento",
  "decrease_alerts": "Alertas de diminuição",
  "average_alerts": "Alertas em média",
  "total_alerts": "Total de alertas",
  "view_alerts": "Ver alertas",
  "alert_type": "Tipo de alerta",
  "alert_status": "Estado de alerta",
  "alert_month": "Mês de alerta",
  "current_consumption": "Consumo atual",
  "comparison_consumption": "Comparação de consumo",
  "variation": "Variação",
  "return_to_average": "Retorno à média",
  "last_year_consumption": "Consumo do último ano",
  "alerts_chart": "Consumo do ano",
  "perform_tracking": "Realizar acompanhamento",
  "ticket": "Ticket",
  "registration_successful": "Registro realizado com sucesso",
  "mark_as_not_real": "Marcar como não realizado",
  "mark_as_not_real_description": "A alerta será marcada como não real e deixará de aparecer",
  "marked_as_not_real": "Marcada como não real",
  "is_real": "É real",
  "digitation_error": "Erro de digitação",
  "new_status": "Novo estado",
  "file_will_arrive_soon": "O arquivo chegará em alguns minutos ao e-mail",
  "revert_closed": "Reverter fechado",
  "confirm_revert_status": "Tem certeza de que deseja reverter o estado desta alerta?",
  "last_tracking_date": "Data do último acompanhamento",
  "showing": "Mostrando",
  "hiding": "Ocultando",
  "parent_accounts": "Contas pai",
  "child_accounts": "Contas filhas",
  "other_accounts": "Outras contas",
  "parent_and_child_accounts": "Contas pai e filho",
  "accounts_excluding_children": "Contas excluindo filhos",
  "accounts_excluding_parents": "Contas excluindo pais",
  "indifferent": "Indiferente",
  "all_companies": "Todas as empresas",
  "load_another_bill": "Carregar outra fatura",
  "view_meter_alerts": "Visualizar alertas do medidor",
  "meter_alerts": "Alertas de medidores",
  "increase": "Aumento",
  "decrease": "Diminuição",
  "net_balance": "Saldo líquido",
  "maximum": "Máximo",
  "optimal_maximum": "Máximo ótimo",
  "average": "Média",
  "minimum": "Mínimo",
  "optimal_minimum": "Mínimo ótimo",
  "chart": "Gráfico",
  "view_full_screen": "Ver tela cheia",
  "last": "Último",
  "tracking": "Acompanhamento",
  "parent_company": "Empresa mãe",
  "next": "Próximo",
  "mark_as_not_real_question": "Tem certeza de que deseja marcar este alerta como não real? Isso também a fechará",
  "comparison": "Comparação",
  "average_1_year": "Média 1 ano",
  "average_6_months": "Média 6 meses",
  "insertFilePlaneClaro": "Insira o arquivo do plano Claro",
  "add_filters": "Adicionar filtros",
  "remove_filter": "Remover filtro",
  "filter_limit_reached": "Limite de filtros alcançado",
  "save_filter_settings": "Salvar configurações de filtro",
  "addUserCredentials": "Adicionar credenciais de usuário",
  "save_filter_prompt": "Se aceitar, a configuração atual do filtro será salva. Cada vez que você abrir este módulo pela primeira vez, esta configuração será carregada",
  "ignore_atypical_consumption": "Ignorar consumo atípico",
  "view_tracking": "Ver acompanhamento",
  "scheduled_start": "Início programado",
  "scheduled_end": "Fim programado",
  "total_lesson": "Total de aulas",
  "progress": "Progresso",
  "eguru_courses": "Cursos do Eguru",
  "instances": "Instâncias",
  "category": "Categoria",
  "course_setup": "Configuração do curso",
  "complete_all_fields": "Complete todos os campos",
  "customize_your_course": "Personalize seu curso",
  "lessons": "Aulas",
  "course_name": "Nome do curso",
  "course_description": "Descrição do curso",
  "course_image": "Imagem do curso",
  "course_chapters": "Capítulos do curso",
  "drag_and_drop_to_reorder_chapters": "Arraste e solte para reordenar os capítulos",
  "active_course": "Curso ativo",
  "no_chapters": "Sem capítulos",
  "add_module": "Adicionar módulo",
  "module_name": "Nome do módulo",
  "add_module_name": "Adicionar nome do módulo",
  "add_chapter": "Adicionar capítulo",
  "compliance": "Conformidade",
  "civil_liability": "Responsabilidade civil",
  "offer_sincerity": "Seriedade da Oferta",
  "insurance_policy": "Política de seguro",
  "subservices": "Subserviços",
  "total_lessons": "Total de aulas",
  "contend": "Conteúdo",
  "datail": "Detalhe",
  "last_access": "Último acesso",
  "instance_status": "Estado da instância",
  "on_progress": "Em progresso",
  "previous_status": "Estado anterior",
  "deactivate_account": "Desativar conta",
  "account_deactivated_successfully": "A conta foi desativada com sucesso",
  "active_period": "Período ativo",
  "case_title": "Título para o caso",
  "create_odoo_ticket": "Criar ticket Odoo",
  "configure_periods": "Configurar períodos",
  "add_date_range": "Adicionar intervalo de datas",
  "thermal_energy": "Energia térmica",
  "total_reviewed": "Total revisado",
  "total_no_reviewed": "Total não revisado",
  "no_reviewed_days": "Dias não revisados",
  "not_review_in_a_last_two_days": "Não revisado nos últimos dois dias",
  "subject": "Assunto",
  "from_email": "De e-mail",
  "has_attachment": "Tem anexo",
  "attachment": "Anexo",
  "is_reviewed": "Está revisado",
  "from": "De",
  "to": "Para",
  "to_me": "Para mim",
  "replay_to": "Responder para",
  "bot_reviewed": "Bot revisado",
  "comment": "Comentário",
  "remove_review": "Remover revisão",
  "edit_review": "Editar revisão",
  "no_reviewed_day": "Dia não revisado",
  "bot_not_reviewed": "Bot não revisado",
  "total_lessons_completed": "Total de aulas concluídas",
  "view_comments": "Ver comentários",
  "total_completed": "Total concluído",
  "in_progress": "Em progresso",
  "comments_instance": "Comments instance",
  "total_comments": "Total de comentários",
  "reload": "Recarregar",
  "comments_description": "Aqui você pode encontrar os comentários de cada lição da instância. Se uma lição não tiver comentários, nada aparecerá para mostrar.",
  "is_completed": "Está concluído",
  "lesson": "Aula",
  "contend_time": "Tempo de conteúdo",
  "id_video": "ID do vídeo",
  "upload_video": "Carregar vídeo",
  "total_pages": "Total de páginas",
  "add_lesson": "Adicionar aula",
  "courses": "Cursos",
  "add_lessons_to_save": "Adicione aulas para salvar o curso",
  "used_massive_log": "Registro em massa foi utilizado",
  "searchBills": "Buscar faturas",
  "bots_execution": "Execução de bots",
  "export_policy": "Exportar apólice",
  "measurement_location": "Local de medição",
  "max_budget": "Orçamento máximo",
  "max_value": "Valor máximo",
  "digital_status": "Estado digital",
  "total_accounts": "Total de contas",
  "total_paymentgis": "Total de pagamentos",
  "execute_accounts_in_this_cluster": "Executar contas neste cluster",
  "image_upload_flag": "La imagen no es obligatoria en este modo. Si se carga una imagen, solo aparecerá en el encabezado del texto.",
  "tags": "Etiquetas",
  "upload_attachment": "Cargar adjunto",
  "include_policy": "Incluir apólice",
  "export_only_policy": "Exportar apenas apólice",
  "attachments": "Adjuntos",
  "more": "Más",
  "total_users": "Total de usuarios",
  "downloaded_files": "Arquivos baixados",
  "parents_only": "Só pais",
  "forum": "Requerimento de comentário para completar a lição.",
  "file_info": "Información del archivo",
  "file_name": "Nombre del archivo",
  "size_file": "Tamaño del archivo",
  "upload_bill_description": "Cargar factura a una cuenta",
  "bill_description_one": "Obtenha uma cópia da fatura que você deseja carregar na conta.",
  "upload_bill_description_two": "Verifique os detalhes da conta a ser cobrada pela fatura, incluindo o número da conta, a empresa e outras informações.",
  "account_info": "Información da conta",
  "see_last_bill": "Ver a última fatura",
  "last_bill": "Última fatura",
  "automatically_upload": "Carregar automaticamente",
  "upload_date": "Data de carga",
  "see_upload_bills_view": "Ver facturas carregadas",
  "see_bill": "Ver factura",
  "report_generated_successfully": "Relatório gerado com sucesso",
  "executions_last_60_min": "Execuções últimos 60 min",
  "available_executions": "Execuções disponíveis",
  "go_back": "Voltar",
  "perform_new_execution": "Realizar nova execução",
  "end": "Fim",
  "execute": "Executar",
  "select_accounts": "Selecionar as contas",
  "selected": "Selecionadas",
  "are_you_sure_to_select": "Tem certeza de selecionar",
  "bot_executed": "Bot executado",
  "bot_executed_message": "Em alguns momentos os bots iniciarão a busca",
  "cancel_execution": "Cancelar execução",
  "without_cluster": "Sem cluster",
  "execute_cluster_and_without_cluster": "Executar cluster {{cluster}} e os que não têm cluster",
  "remove_selection": "Remover seleção",
  "last_execution": "Última execução",
  "max_characters_allowed": "Máximo de {{charactersAllowed}} caracteres permitidos",
  "successful_export": "Exportação bem-sucedida",
  "info_will_arrive_in_minutes_to_email": "As informações chegarão em alguns minutos ao e-mail {{email}}",
  "account_change": "Mudança de conta",
  "massive_updates": "Atualizações em massa",
  "close_novelties": "Fechar novidades",
  "close_massive_novelties_confirm": "Se você aceitar todas as novidades em massa desta execução, elas serão fechadas",
  "add_massive_comments": "Adicionar comentário em massa",
  "invoice_uploader": "Carregador de faturas",
  "created_by_cluster": "Criado por cluster",
  "are_you_sure": "Tem certeza?",
  "if_chose_search_bills_tab_you_lose_email_filters": "Se você escolher a guia de pesquisa de faturas, você perderá os filtros de e-mail",
  "legal_name": "Nome legal",
  "nit": "NIT",
  "document_expiration_date": "Data de validade do documento",
  "operation_type": "Tipo de operação",
  "invoices_not_uploaded_this_month": "Faturas não carregadas este mês",
  "urgent": "Urgente",
  "see_actual_bill": "Ver factura real",
  "bill_already_uploaded": "Factura ya cargada",
  "bill_already_uploaded_description": "La factura ya se ha cargado a la cuenta",
  "last_invoice_uploaded": "Última fatura carregada",
  "add_hide_reminder": "Agregar ocultar recordatorio",
  "add_item": "Agregar item",
  "hide_senders": "Ocultar remetentes",
  "hide_senders_prompt": "Aquí puede ocultar correos de remitentes. Agregue la dirección de correo electrónico que desea excluir a la lista",
  "clear_filters": "Limpiar filtros",
  "stats": "Estatísticas",
  "success_message": "La lista de correos ocultos se ha actualizado",
  "review_result": "Resultado de revisión",
  "order_by": "Ordenar por",
  "review_duplicate_bill": "Revisar factura duplicada",
  "non_billing_email": "Correo no es de facturación",
  "child": "Hijo",
  "id": "ID",
  "attachments_type": "Tipo de adjuntos",
  "ticket_code": "Código de ticket",
  "add_all_the_accounts": "Agregar todas as contas",
  "add_all_the_accounts_confirm": "Se você aceitar, todas as contas que atenderem a esses filtros serão adicionadas",
  "invoice_fields": "Campos de faturas",
  "no_emails_selected": "Nenhum e-mail selecionado",
  "select_emails_to_view": "Selecione os e-mails para visualizar",
  "no_emails_data": "Nenhum dado de e-mail",
  "inactive_account": "Conta inativa",
  "daily": "Diário",
  "weekly": "Semanal",
  "monthly": "Mensal",
  "custom": "Personalizado",
  "today": "Hoje",
  "TwoDays": "Dois dias",
  "SevenDays": "Sete dias",
  "30Days": "30 dias",
  "non_existent": "Não existente",
  "status_previously_listed": "Status previamente guardado",
  "block_subject": "Assunto de bloqueio",
  "block_email": "E-mail de bloqueio",
  "add_hidden_item": "Adicionar item oculto",
  "empty_hidden_fields": "Por favor, adicione pelo menos um item oculto",
  "other_concepts": "Outros conceitos",
  "credit_note": "Nota de crédito",
  "bots_will_be_executed_for": "Bots serão executados para",
  "of_them_will_run_as_doubles": "delas serão executadas como duplas",
  "original_sender": "Remetente original",
  "time_elapsed": "Tempo decorrido",
  "clean": "Limpar",
  "visit_date": "Data de visita",
  "orden": "Ordem",
  "error_subtitle": "Ops! Ocorreu um erro",
  "error_description": "Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde.",
  "initial_balance": "Saldo inicial",
  "odoo_ticket_id": "ID do ticket Odoo",
  "ticket_type": "Tipo de ticket",
  "model": "Modelo",
  "company_assignment": "Atribuição de empresa",
  "company_assigned_successfully": "Empresa atribuída com sucesso",
  "accounts_petitions": "Petição de conta",
  "company_name": "Nome da empresa",
  "marketer_name": "Nome do comercializador",
  "marketer_email": "E-mail do comercializador",
  "has_bill": "Tem fatura",
  "first_send": "Primeira envio",
  "retries": "Tentativas",
  "last_attempt": "Último tentativa",
  "uploaders": "Carregadores",
  "details": "Detalhes",
  "open_extras": "Extras abertos",
  "one_month_comparation": "Comparação de um mês",
  "one_month_variation": "Variação de um mês",
  "one_month_variation_percent": "Variação de um mês (%)",
  "three_months_comparation": "Comparação de três meses",
  "three_months_variation": "Variação de três meses",
  "three_months_variation_percent": "Variação de três meses (%)",
  "six_months_comparation": "Comparação de seis meses",
  "six_months_variation": "Variação de seis meses",
  "six_months_variation_percent": "Variação de seis meses (%)",
  "annual_comparation": "Comparação anual",
  "annual_variation": "Variação anual",
  "annual_variation_percent": "Variação anual (%)",
  "alert_value_percent_max_limit_message": "O valor atual supera o {{percent}}% de variação relativa ao {{comparison}}",
  "annual_comparison": "Ano anterior",
  "one_month_comparison": "Mês anterior",
  "six_months_comparison": "6 meses atrás",
  "three_months_comparison": "3 meses atrás",
  "triggered_alerts": "Alertas disparadas",
  "details_of": "Detalhes de",
  "bill_alerts": "Alertas de faturas",
  "status_last_followup": "Status últ. acompanhamento",
  "pending": "Pendente",
  "managed": "Gerido",
  "current_value": "Valor atual",
  "seccess": "Sucesso",
  "operationSuccessful": "Operação realizada com sucesso"
}
